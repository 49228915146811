<template>
  <BaseWidget :lg="true">
    <div class="widget__tabs">
      <div class="widget__tabs__tab widget__tabs__tab--active">
        My Tasks Due Soon
      </div>
      <div class="widget__tabs__tab">
        Assigned Team Tasks
      </div>
    </div>

    <div class="widget__content">
      <BaseProgressBar label="16 tasks completed out of 25" progress="64">
        <template #value-html>
          <div class="progress-bar__value">
            <span class="progress-bar__value--muted">
              Show:
            </span>
            This week
            <img src="@/assets/images/triangle.svg" />
          </div>
        </template>
      </BaseProgressBar>

      <div class="widget__tasks">
        <div class="widget__tasks__header">
          <div class="widget__tasks__col widget__tasks__col--lg">Task name</div>
          <div class="widget__tasks__col">Assignee</div>
          <div class="widget__tasks__col">Due date</div>
          <div class="widget__tasks__col">Priority</div>
          <div class="widget__tasks__col"></div>
        </div>

        <div class="widget__tasks__task">
          <div
            class="widget__tasks__col widget__tasks__col--lg widget__tasks__col--name"
          >
            Send review to Alexandra McQueen
          </div>
          <div class="widget__tasks__col">
            <img src="@/assets/images/user.png" />
          </div>
          <div class="widget__tasks__col widget__tasks__col--date">Friday</div>
          <div class="widget__tasks__col widget__tasks__col--priority">
            <div class="widget__tasks__badge widget__tasks__badge--low">
              Low
            </div>
          </div>
          <div class="widget__tasks__col">
            <img
              class="widget__tasks__col__icon"
              src="@/assets/images/ellipsis.svg"
            />
          </div>
        </div>

        <div class="widget__tasks__task">
          <div
            class="widget__tasks__col widget__tasks__col--lg widget__tasks__col--name"
          >
            Send review to Alexandra McQueen
          </div>
          <div class="widget__tasks__col">
            <img src="@/assets/images/user.png" />
          </div>
          <div class="widget__tasks__col widget__tasks__col--date">Friday</div>
          <div class="widget__tasks__col widget__tasks__col--priority">
            <div class="widget__tasks__badge widget__tasks__badge--medium">
              Medium
            </div>
          </div>
          <div class="widget__tasks__col">
            <img
              class="widget__tasks__col__icon"
              src="@/assets/images/ellipsis.svg"
            />
          </div>
        </div>

        <div class="widget__tasks__task">
          <div
            class="widget__tasks__col widget__tasks__col--lg widget__tasks__col--name"
          >
            Send review to Alexandra McQueen
          </div>
          <div class="widget__tasks__col">
            <img src="@/assets/images/user.png" />
          </div>
          <div class="widget__tasks__col widget__tasks__col--date">Friday</div>
          <div class="widget__tasks__col widget__tasks__col--priority">
            <div class="widget__tasks__badge widget__tasks__badge--high">
              High
            </div>
          </div>
          <div class="widget__tasks__col">
            <img
              class="widget__tasks__col__icon"
              src="@/assets/images/ellipsis.svg"
            />
          </div>
        </div>

        <div class="widget__tasks__task">
          <div
            class="widget__tasks__col widget__tasks__col--lg widget__tasks__col--name"
          >
            Send review to Alexandra McQueen
          </div>
          <div class="widget__tasks__col">
            <img src="@/assets/images/user.png" />
          </div>
          <div class="widget__tasks__col widget__tasks__col--date">Friday</div>
          <div class="widget__tasks__col widget__tasks__col--priority">
            <div class="widget__tasks__badge widget__tasks__badge--low">
              Low
            </div>
          </div>
          <div class="widget__tasks__col">
            <img
              class="widget__tasks__col__icon"
              src="@/assets/images/ellipsis.svg"
            />
          </div>
        </div>

        <div class="widget__tasks__task">
          <div
            class="widget__tasks__col widget__tasks__col--lg widget__tasks__col--name"
          >
            Send review to Alexandra McQueen
          </div>
          <div class="widget__tasks__col">
            <img src="@/assets/images/user.png" />
          </div>
          <div class="widget__tasks__col widget__tasks__col--date">Friday</div>
          <div class="widget__tasks__col widget__tasks__col--priority">
            <div class="widget__tasks__badge widget__tasks__badge--medium">
              Medium
            </div>
          </div>
          <div class="widget__tasks__col">
            <img
              class="widget__tasks__col__icon"
              src="@/assets/images/ellipsis.svg"
            />
          </div>
        </div>

        <div class="widget__tasks__task">
          <div
            class="widget__tasks__col widget__tasks__col--lg widget__tasks__col--name"
          >
            Send review to Alexandra McQueen
          </div>
          <div class="widget__tasks__col">
            <img src="@/assets/images/user.png" />
          </div>
          <div class="widget__tasks__col widget__tasks__col--date">Friday</div>
          <div class="widget__tasks__col widget__tasks__col--priority">
            <div class="widget__tasks__badge widget__tasks__badge--high">
              High
            </div>
          </div>
          <div class="widget__tasks__col">
            <img
              class="widget__tasks__col__icon"
              src="@/assets/images/ellipsis.svg"
            />
          </div>
        </div>

        <div class="widget__tasks__options">
          <button class="widget__tasks__button widget__tasks__button--link">
            Show More
          </button>
          <button class="widget__tasks__button widget__tasks__button--primary">
            <img src="@/assets/images/plus.svg" />
            Add a task
          </button>
        </div>
      </div>
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'
import BaseProgressBar from '@/components/widgets/BaseProgressBar'

export default {
  components: { BaseWidget, BaseProgressBar }
}
</script>

<style lang="scss" scoped>
.widget {
  // width: 594px;

  &__tabs {
    border-bottom: 1px solid #ddd;
    margin-bottom: 27px;
    padding: 25px 11px 0;

    &::after {
      display: none;
    }

    &__tab {
      &--active {
        &::after {
          margin-bottom: -1px;
        }
      }
    }
  }

  &__content {
    padding: 0 27px 34px;

    ::v-deep .progress-bar {
      &__header {
        font-weight: 500;
        margin-bottom: 17px;
      }

      &__value {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-weight: 400;
        gap: 4px;

        &--muted {
          color: #6a707e;
        }
      }
    }
  }

  &__tasks {
    &__header {
      border-bottom: 1px solid #ddd;
      color: #9b9bb8;
      display: flex;
      font-size: 10px;
      font-weight: 700;
      justify-content: space-between;
      letter-spacing: 1.5px;
      line-height: 16px;
      margin: 19px 0 7px 0;
      padding-bottom: 7px;
      text-transform: uppercase;
    }

    &__task {
      align-items: center;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-between;
      padding: 14px 0;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    &__col {
      flex-basis: 0;
      flex-grow: 1;
      margin-left: 12px;

      &--lg {
        flex-grow: 2;
      }

      &--name {
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &--date,
      &--priority {
        font-size: 13px;
        font-weight: 400;
      }

      &__icon {
        display: block;
        margin: 0 24px 0 auto;
      }
    }

    &__badge {
      border-radius: 100px;
      line-height: 16px;
      text-align: center;
      width: 71px;

      &--low {
        background: #76e1a7;
      }

      &--medium {
        background: #ffba69;
      }

      &--high {
        background: #ff6969;
      }
    }

    &__options {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 27px;
    }

    &__button {
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      height: 30px;

      &--link {
        background: transparent;
        color: #081f32;
      }

      &--primary {
        align-items: center;
        background: #081f32;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
        color: #fff;
        display: inline-flex;
        gap: 8px;
        padding: 0 13px;
      }
    }
  }
}
</style>
