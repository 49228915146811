<template>
  <div class="row">
    <div class="row__column row__column--1">
      <CensusWidget class="mb" />
      <RatesWidget class="mb stretch-widget" />
      <EmployeeUsageWidget />
    </div>

    <div class="row__column row__column--2">
      <ExecutiveDashboardSummary class="mb" />
      <RevenueChartsWidget class="mb mt stretch-widget" />
      <ExecutiveArAgingWidget />
    </div>

    <div class="row__column row__column--3">
      <CashFlowWidget class="mb" />
      <DailySalesOutstandingWidget class="mb" />
      <ClaimsWidget class="stretch-widget" />
    </div>
  </div>
</template>

<script>
import ExecutiveDashboardSummary from '@/components/widgets/ExecutiveDashboardSummary'
import CensusWidget from '@/components/widgets/CensusWidget'
import EmployeeUsageWidget from '@/components/widgets/EmployeeUsageWidget'
import DailySalesOutstandingWidget from '@/components/widgets/DailySalesOutstandingWidget'
import ClaimsWidget from '@/components/widgets/ClaimsWidget'
import CashFlowWidget from '@/components/widgets/CashFlowWidget'
import ExecutiveArAgingWidget from '@/components/widgets/ExecutiveArAgingWidget'
import RevenueChartsWidget from '@/components/widgets/RevenueChartsWidget'
import RatesWidget from '@/components/widgets/RatesWidget'

export default {
  components: {
    EmployeeUsageWidget,
    ExecutiveDashboardSummary,
    CensusWidget,
    DailySalesOutstandingWidget,
    ClaimsWidget,
    CashFlowWidget,
    ExecutiveArAgingWidget,
    RevenueChartsWidget,
    RatesWidget
  }
}
</script>

<style lang="scss" scoped>
.mb {
  margin-bottom: 24px;
}

.mt {
  margin-top: 45px;
}

.stretch-widget {
  flex: 1;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: 28px;

  &__column {
    display: flex;
    flex-direction: column;

    &--1 {
      max-width: 290px;
    }

    &--2 {
      flex-grow: 1;
      min-width: 827px;
      padding: 0 55px;

      @media only screen and (max-width: 1800px) {
        padding: 0 27px;
      }
    }

    &--3 {
      max-width: 290px;
    }
  }
}
</style>
