<template>
  <div class="patient-report-container">
    <div class="data-source">
      <span class="data-source__tab data-source__tab--active">
        SimpleLTC
        <span class="data-source__tab__active-bar"></span>
      </span>
      <span class="data-source__tab">
        <span
          v-if="$store.state.typeOfSideBySide !== 'default'"
          class="data-source__tab__amount"
        >
          1
        </span>
        PCC
      </span>
      <span class="data-source__tab">
        Claims
      </span>
      <span class="data-source__tab">
        Forms
      </span>
    </div>
    <div class="data-source">
      <span class="data-source__tab data-source__tab--active">
        <span
          v-if="$store.state.typeOfSideBySide !== 'default'"
          class="data-source__tab__amount"
        >
          1
        </span>
        Amerigroup
        <span class="data-source__tab__active-bar"></span>
      </span>
      <span class="data-source__tab">
        TMHP
      </span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.patient-report-container {
  background: #fff;
  color: #000;
  column-gap: 42px;
  display: grid;
  font-size: 18px;
  font-weight: bold;
  grid-template-columns: 1fr 1fr;
  line-height: 30px;
  padding: 32px 128px 0 128px;
}

.data-source {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding-bottom: 12px;

  &__tab {
    cursor: pointer;
    font-weight: normal;
    margin-left: 10px;
    margin-right: 14px;
    padding-bottom: 13px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;

    &--active {
      font-weight: bold;
    }

    &__amount {
      background: #fcdede;
      border-radius: 4px;
      color: #e80707;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      height: 24px;
      line-height: 24px;
      margin-right: 8px;
      min-width: 21px;
      text-align: center;
    }

    &__active-bar {
      background: #0d283e;
      border-radius: 4px 4px 0 0;
      bottom: -4px;
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}
</style>
