<template>
  <div class="modal__header">
    <div>
      <p class="modal__header__label">Patient Name</p>
      <p class="modal__header__value">
        {{
          $store.state.typeOfSideBySide === 'default'
            ? 'Anthony Abbe'
            : 'Florina Abeyta'
        }}
      </p>
    </div>
    <div>
      <p class="modal__header__label">Facility Name</p>
      <p class="modal__header__value">
        {{
          $store.state.typeOfSideBySide === 'default'
            ? 'Mountain View Health & Rehabilitation'
            : 'Lakeside Manor'
        }}
      </p>
    </div>
    <div style="text-align: center">
      <p class="modal__header__label">CoreCare Status</p>
      <div
        v-if="$store.state.typeOfSideBySide === 'default'"
        class="modal__header__badge modal__header__badge--ok"
      >
        OK
      </div>
      <div v-else class="modal__header__badge modal__header__badge--ok">
        Needs review
      </div>
    </div>
    <div style="text-align: center">
      <p class="modal__header__label">Alerts</p>
      <div
        v-if="$store.state.typeOfSideBySide === 'default'"
        class="modal__header__badge"
      >
        No Alerts
      </div>
      <div v-else class="relative-position">
        <div
          class="modal__header__badge modal__header__badge--alerts"
          :class="{
            'modal__header__badge--alerts--active': alertsTooltipActive
          }"
          @click="toggleAlertsTooltip"
        >
          2 Alerts
        </div>
        <SideBySideAlertsTooltip
          ref="alertsTooltip"
          @toggle="toggleAlertsTooltip"
          @close="closeAlertsTooltip"
        />
      </div>
    </div>
    <div>
      <p class="modal__header__label">Last updated</p>
      <p class="modal__header__value">01/04/2020</p>
    </div>
    <div class="modal__header__options">
      <div
        class="modal__header__options__button"
        @click.stop="$eventHub.$emit('toggle-resident-notes-panel')"
      >
        <img src="@/assets/images/notes.svg" />
      </div>
      <img src="@/assets/images/bookmark.svg" />
      <img src="@/assets/images/kabob.svg" />
      <div class="modal__header__options__divider"></div>
      <div class="modal__header__options__button" @click="$emit('close')">
        <img src="@/assets/images/close-black.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import SideBySideAlertsTooltip from '@/components/tooltips/SideBySideAlertsTooltip'

export default {
  components: {
    SideBySideAlertsTooltip
  },
  data() {
    return {
      alertsTooltipActive: false
    }
  },
  methods: {
    toggleAlertsTooltip() {
      this.alertsTooltipActive = !this.alertsTooltipActive
      this.$refs.alertsTooltip.isVisible = !this.$refs.alertsTooltip.isVisible
    },
    closeAlertsTooltip() {
      this.alertsTooltipActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__header {
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  padding: 14px 0;
  position: sticky;
  top: 48px;
  z-index: 999;

  &__label {
    color: #333;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &__value {
    color: #1d1c1d;
    font-size: 16px;
  }

  &__badge {
    align-items: center;
    background: #fff;
    border: 1px solid #1d1c1d;
    border-radius: 99px;
    color: #1d1c1d;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    gap: 5px;
    height: 20px;
    line-height: 20px;
    padding: 2px 9px;
    position: relative;
    text-align: center;

    &--ok {
      padding: 2px 15px;
    }

    &:hover {
      background: #eef0f3;
    }

    &--alerts {
      border-color: #5900d0;
      color: #5900d0;
      width: 78px;

      &:hover,
      &--active {
        background: #5900d0;
        color: #fff;
      }

      &::after {
        background-image: url('../../assets/images/alerts-arrow.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 5px;
        position: absolute;
        right: 8.25px;
        top: calc(50% - 1.8px);
        width: 8.75px;
      }

      &:hover::after,
      &--active::after {
        background-image: url('../../assets/images/alerts-arrow-hover.svg');
      }
    }
  }

  &__options {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 40px;

    &__divider {
      background: #ccc;
      height: 30px;
      width: 1px;
    }

    &__button {
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 36px;
      justify-content: center;
      margin-left: -14px;
      width: 36px;

      &:hover {
        background: #eef0f3;
      }
    }
  }
}
</style>
