<template>
  <div class="progress-bar">
    <div class="progress-bar__header">
      <span>{{ label }}</span>
      <span v-if="value !== null">{{ value }}</span>
      <slot v-else name="value-html"></slot>
    </div>
    <div class="progress-bar__bar">
      <div
        class="progress-bar__bar--progressed"
        :style="{ width: progress + '%', background: color }"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    progress: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  &__header {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
    margin-bottom: 2px;
  }

  &__bar {
    background: #dbdff1;
    border-radius: 6px;
    height: 4px;
    position: relative;
    width: 100%;

    &--progressed {
      background: linear-gradient(270deg, #0e3457 0%, #501b7b 100%);
      border-radius: 6px;
      height: 6px;
      left: 0;
      position: absolute;
      top: -1px;
    }
  }
}
</style>
