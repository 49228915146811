<template>
  <BaseWidget>
    <img src="@/assets/images/half-circle.svg" class="half-circle" />

    <ProgressCircleHalf :stroke-dashoffset="strokeDashoffset" />

    <span class="widget__icon">
      <img src="@/assets/images/users.svg" />
    </span>

    <div class="widget__title">Total employee usage</div>

    <div class="percentages">
      <span class="percentages__label">0%</span>
      <span class="percentages__label">100%</span>
    </div>

    <div class="progress">
      <span class="progress__percentage">{{ progressPercentage }}</span>
      <span class="progress__symbol">%</span>
    </div>

    <div class="widget__subtitle">Current billing cycle</div>

    <div class="progress-bar-container">
      <BaseProgressBar
        label="Managers"
        value="100%"
        progress="100"
        color="#ffba69"
      />
    </div>

    <div class="progress-bar-container">
      <BaseProgressBar
        label="End Users"
        value="88%"
        progress="88"
        color="#8375fd"
      />
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'
import ProgressCircleHalf from '@/components/svg/ProgressCircleHalf'
import BaseProgressBar from '@/components/widgets/BaseProgressBar'

export default {
  components: { BaseWidget, ProgressCircleHalf, BaseProgressBar },

  data() {
    return {
      progressPercentage: 92
    }
  },

  computed: {
    strokeDashoffset() {
      return 150 - (this.progressPercentage * 150) / 100
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 50px 21px 27px 21px;
  position: relative;
  width: 290px;

  .half-circle {
    left: 18px;
    position: absolute;
    top: 29px;
  }

  .half-progress-circle {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__icon {
    align-items: center;
    background: #dbdff1;
    border-radius: 50%;
    display: flex;
    height: 36px;
    justify-content: center;
    margin: -120px auto 16px auto;
    width: 36px;
  }

  &__title {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }

  .percentages {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    padding: 0 14px 0 27px;

    &__label {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .progress {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: -38px;

    &__percentage {
      font-size: 64px;
      font-weight: 700;
      letter-spacing: -2.4px;
      line-height: 50px;
    }

    &__symbol {
      font-size: 21px;
      font-weight: 500;
    }
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 24px;
    opacity: 0.6;
    text-align: center;
  }

  .progress-bar-container {
    background: #f0f3ff;
    border-radius: 6px;
    padding: 19px 16px 16px 16px;

    &:not(:last-of-type) {
      margin-bottom: 13px;
    }

    ::v-deep .progress-bar__header {
      font-size: 12px;
      margin-bottom: 12px;
    }
  }
}
</style>
