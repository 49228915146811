<template>
  <table class="table">
    <thead class="table__header">
      <tr>
        <th colspan="7" class="table__header__th">
          Medicaid Eligibility
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr>
        <th class="table__body__th">#</th>
        <th class="table__body__th">Eff. date</th>
        <th class="table__body__th">End date</th>
        <th class="table__body__th">Coverage code</th>
        <th class="table__body__th">Secondary coverage code</th>
        <th class="table__body__th">Type program</th>
        <th class="table__body__th">Category code</th>
      </tr>
      <tr v-for="(item, i) in data" :key="i">
        <td class="table__body__td">
          {{ i + 1 }}
        </td>
        <td class="table__body__td">
          {{ item.effDate }}
        </td>
        <td class="table__body__td">
          {{ item.endDate }}
        </td>
        <td class="table__body__td">
          {{ item.coverageCode }}
        </td>
        <td class="table__body__td">
          {{ item.secondaryCoverageCode }}
        </td>
        <td class="table__body__td">
          {{ item.programType }}
        </td>
        <td class="table__body__td">
          {{ item.categoryCode }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      instances: {
        default: {
          left: [
            {
              effDate: '',
              endDate: '',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            },
            {
              effDate: '03/01/2014',
              endDate: '12/31/2019',
              programType: '14',
              categoryCode: '4',
              coverageCode: 'R',
              secondaryCoverageCode: 'B'
            },
            {
              effDate: '01/01/2020',
              endDate: '04/30/2020',
              programType: '14',
              categoryCode: '4',
              coverageCode: 'R',
              secondaryCoverageCode: 'B'
            },
            {
              effDate: '05/01/2020',
              endDate: '12/31/9999',
              programType: '14',
              categoryCode: '4',
              coverageCode: 'R',
              secondaryCoverageCode: 'B'
            },
            {
              effDate: '',
              endDate: '',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            }
          ],
          right: [
            {
              effDate: '03/01/2012',
              endDate: '05/16/2020',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            },
            {
              effDate: '',
              endDate: '',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            },
            {
              effDate: '',
              endDate: '',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            },
            {
              effDate: '09/01/2020',
              endDate: '12/31/9999',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            },
            {
              effDate: '05/17/2020',
              endDate: '08/31/2020',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            }
          ]
        },
        florina: {
          left: [
            {
              effDate: '07/29/2016',
              endDate: '12/31/9999',
              programType: '14',
              categoryCode: '4',
              coverageCode: 'R',
              secondaryCoverageCode: 'Q'
            },
            {
              effDate: '',
              endDate: '',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            },
            {
              effDate: '',
              endDate: '',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            }
          ],
          right: [
            {
              effDate: '10/15/2020',
              endDate: '12/31/9999',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            },
            {
              effDate: '07/01/2018',
              endDate: '08/31/2020',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            },
            {
              effDate: '09/01/2020',
              endDate: '10/14/2020',
              programType: '',
              categoryCode: '',
              coverageCode: '',
              secondaryCoverageCode: ''
            }
          ]
        }
      }
    }
  },
  computed: {
    data() {
      return this.instances[this.$store.state.typeOfSideBySide][this.type]
    }
  }
}
</script>
