<template>
  <div
    :class="{ widget: true, 'widget--lg': lg, 'widget--no-effect': noEffect }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    lg: {
      type: Boolean,
      default: false
    },
    noEffect: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 100px 80px rgba(108, 73, 172, 0.07),
    0 42px 34px rgba(108, 73, 172, 0.05), 0 22px 18px rgba(108, 73, 172, 0.04),
    0 12px 10px rgba(108, 73, 172, 0.035), 0 7px 5px rgba(108, 73, 172, 0.028),
    0 3px 2px rgba(108, 73, 172, 0.02);
  color: #383874;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &--lg:hover {
    transform: scale(1.05);
  }

  &--no-effect:hover {
    transform: scale(1);
  }

  &__title {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.44px;
    line-height: 24px;
  }

  &__percentage--up {
    align-items: baseline;
    color: #00b929;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__subtext {
    color: #383874;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    opacity: 0.6;
  }

  &__dropdown {
    align-items: center;
    background: #fff;
    border-radius: 100px;
    box-shadow: 0 1px 3px rgba(32, 12, 74, 0.16);
    display: flex;
    font-size: 13px;
    font-weight: 300;
    height: 30px;
    justify-content: space-between;
    padding: 0 12px 0 15px;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
    position: relative;
    width: 100%;

    &::after {
      background-color: #ddd;
      bottom: 0;
      content: '';
      height: 1px;
      left: -30px;
      position: absolute;
      width: calc(100% + 60px);
    }

    &__tab {
      color: #0d283e;
      cursor: pointer;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.1px;
      line-height: 16px;
      margin-right: 45px;
      padding: 0 13px 13px 13px;
      position: relative;
      text-align: center;

      &--active {
        font-weight: 500;

        &::after {
          background-image: url('../../assets/images/tab-active.svg');
          background-size: 100% 100%;
          bottom: 0;
          content: '';
          cursor: inherit;
          height: 4px;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
