<template>
  <BaseWidget :lg="true">
    <div class="widget__tabs">
      <div class="widget__tabs__tab widget__tabs__tab--active">
        Accelerated Revenue
      </div>
      <div class="widget__tabs__tab">
        Increased Revenue
      </div>
    </div>
    <div class="widget__data">
      <div class="widget__data__value">
        <span class="widget__data__value__label">Accelerated Revenue</span>
        <span class="widget__data__value__value">$25,054,006</span>
        <span class="widget__data__value__subtext">Year to date</span>
      </div>
      <div class="widget__data__selector">
        <div>
          <div class="widget__data__selector__button">
            Current billing period
          </div>
          <div
            class="widget__data__selector__button widget__data__selector__button--active"
          >
            Monthly
          </div>
          <div class="widget__data__selector__button">
            Yearly
          </div>
        </div>
      </div>
    </div>
    <div class="widget__chart">
      <AcceleratedRevenueChart />
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'
import AcceleratedRevenueChart from '@/components/helpers/AcceleratedRevenueChart'

export default {
  components: { BaseWidget, AcceleratedRevenueChart }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px 30px;
  // width: 1109px;

  &__tabs {
    padding: -20px -30px;
  }

  &__chart {
    // width: 100%;
  }

  &__data {
    display: flex;
    margin-bottom: 38px;
    width: 100%;

    @media only screen and (max-width: 1600px) {
      margin-top: 50px;
    }

    &__value {
      display: flex;
      flex-direction: column;
      margin-right: auto;

      &__label {
        color: #383874;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.44px;
        line-height: 24px;
      }

      &__value {
        color: #383874;
        font-size: 34px;
        letter-spacing: -0.44px;
        line-height: 44px;
      }

      &__subtext {
        color: #383874;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 16px;
        opacity: 0.6;
      }
    }

    &__selector {
      display: flex;
      margin-left: auto;

      &__button {
        border-radius: 16px;
        color: #0d283e;
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.02px;
        line-height: 16px;
        margin-right: 13px;
        padding: 6px 16px;
        text-align: center;
        transition: 0.2s ease-in-out;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover,
        &--active {
          background: #383874;
          color: #fff;
          font-size: 14px;
          letter-spacing: 0.44px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
