<template>
  <div class="tooltip-container">
    <div
      :class="['tooltip-label', { 'tooltip-label--active': isVisible }]"
      @click="isVisible = !isVisible"
    >
      {{ label }}
    </div>
    <div class="tooltip" v-if="isVisible" v-click-outside="close">
      <p class="tooltip__label">
        <img src="@/assets/images/shield.svg" />
        Medicaid ID
      </p>
      <p class="tooltip__value">607967492</p>
      <p class="tooltip__label">
        <img src="@/assets/images/calendar.svg" />
        Date of Birth
      </p>
      <p class="tooltip__value">7/10/1947</p>
      <p class="tooltip__label">
        <img src="@/assets/images/user.svg" />
        Gender
      </p>
      <p class="tooltip__value">Male</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    close() {
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-container {
  display: flex;
  position: relative;
}

.tooltip-label {
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  padding: 3px 12px;

  &:hover,
  &--active {
    background: #eef0f3;
  }
}

.tooltip {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.34);
  left: 12px;
  padding: 12px 14px;
  position: absolute;
  text-align: left;
  top: 30px;
  width: 184px;
  z-index: 2;

  &__label {
    color: #5f5f5f;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 8px;
    line-height: 24px;
  }

  &__value {
    font-size: 16px;
    line-height: 24px;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  &__item {
    color: #000;
    display: flex;
    font-size: 14px;
    height: 46px;
    justify-content: space-between;
    line-height: 24px;
    padding: 11px 14px;

    &__button {
      background: #fff;
      border: 1px solid #adb1b8;
      border-radius: 4px;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      height: 26px;
      margin-left: 14px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #dadada;
    }
  }
}
</style>
