<template>
  <BaseTable :rows="rows" :selectable="false">
    <template slot="header">
      <template>
        <td>Resident</td>
        <td>Patient ID</td>
        <td>Facility Name</td>
        <td>Last Viewed</td>
        <td>Last Updated</td>
        <td>Payor</td>
        <td>CoreCare Status</td>
        <td>Alerts</td>
      </template>
    </template>
    <template slot="row" slot-scope="{ row }">
      <template>
        <td v-for="(cell, j) in row.data" :key="j">
          <div
            class="table-alerts"
            v-if="cell.dynamic && cell.type === 'alerts'"
          >
            <button
              class="table-alerts-button"
              @click="toggleTooltip(`alerts-tooltip-${row.id}`)"
            >
              {{ cell.value }} Alerts
            </button>

            <DynamicAlertsTooltip
              :ref="`alerts-tooltip-${row.id}`"
              :amount-of-errors="cell.value"
              @toggle="toggleTooltip(`alerts-tooltip-${row.id}`)"
              :alert-labels="cell.alertLabels"
            />
          </div>
          <button
            v-else-if="cell.dynamic && cell.type === 'status'"
            class="table-button table-button--hover-primary"
          >
            Needs review
          </button>
          <span v-else-if="cell.type === 'resident'">
            <ResidentTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </span>
          <span v-else-if="cell.type === 'facility'">
            <FacilityTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </span>
          <span v-else>
            {{ cell }}
          </span>
        </td>
      </template>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable'
import DynamicAlertsTooltip from '@/components/tooltips/DynamicAlertsTooltip'
import ResidentTooltip from '@/components/tooltips/ResidentTooltip'
import FacilityTooltip from '@/components/tooltips/FacilityTooltip'

const rowTemplate = (
  id,
  alertLabels,
  patientName,
  medicaidId,
  facilityName,
  payorName
) => ({
  id,
  data: [
    {
      value: patientName,
      type: 'resident'
    },
    medicaidId,
    {
      value: facilityName,
      type: 'facility'
    },
    'Yesterday',
    '01/04/2020',
    payorName,
    {
      dynamic: true,
      type: 'status'
    },
    {
      dynamic: true,
      type: 'alerts',
      value: 2,
      alertLabels
    }
  ]
})

export default {
  components: {
    BaseTable,
    DynamicAlertsTooltip,
    ResidentTooltip,
    FacilityTooltip
  },
  data() {
    return {
      rows: [
        rowTemplate(
          1,
          ['New Medicaid ID', 'Payor change'],
          'Vitela, Francisco',
          '36356184',
          'Lakeside Manor',
          'Amerigroup'
        ),
        rowTemplate(
          2,
          ['Applied Income Mismatch (PCC)', 'RUG Level Mismatch (Amerigroup)'],
          'Abeyta, Florina',
          '59756664',
          'St. Barnabas Home',
          'Cigna'
        ),
        rowTemplate(
          3,
          ['Retro Changes', 'DOB Mismatch (Superior)'],
          'Acosta, Porfirio',
          '85305638',
          'Long Term Care Center of America',
          'UnitedHealth'
        ),
        rowTemplate(
          4,
          ['Invalid Service Authorization', 'Missing Form (3618)'],
          'Blanton, James',
          '58357574',
          'Mountain Peace North',
          'Amerigroup'
        ),
        rowTemplate(
          5,
          ['Assessment In-Progress', 'Applied Income Change'],
          'Black, Harry',
          '29182636',
          'Lakeside Manor',
          'Cigna'
        ),
        rowTemplate(
          6,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Cannada, Dorothy',
          '32465834',
          'St. Barnabas Home',
          'Amerigroup'
        ),
        rowTemplate(
          7,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Cave, Randy',
          '12345372',
          'Long Term Care Center of America',
          'UnitedHealth'
        ),
        rowTemplate(
          8,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Ferrell, Jerry',
          '93752649',
          'Lakeside Manor',
          'Molina'
        ),
        rowTemplate(
          9,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Green, Hattie',
          '29357574',
          'St. Barnabas Home',
          'Amerigroup'
        ),
        rowTemplate(
          10,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Gwin, Anita',
          '19284465',
          'Long Term Care Center of America',
          'Amerigroup'
        ),
        rowTemplate(
          11,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Gunkel, Michael',
          '49274754',
          'Lakeside Manor',
          'Cigna'
        ),
        rowTemplate(
          12,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Grissom, Douglas',
          '29346523',
          'St. Barnabas Home',
          'Molina'
        ),
        rowTemplate(
          13,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Glee, Marissa',
          '23734646',
          'Long Term Care Center of America',
          'Amerigroup'
        ),
        rowTemplate(
          14,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Griffin, Louis',
          '123126722',
          'Lakeside Manor',
          'UnitedHealth'
        ),
        rowTemplate(
          15,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'McAlpha, John',
          '124129043',
          'St. Barnabas Home',
          'Amerigroup'
        ),
        rowTemplate(
          16,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Merryweather, Lisa',
          '12384946',
          'Long Term Care Center of America',
          'Cigna'
        ),
        rowTemplate(
          17,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Norman, Paul',
          '92744655',
          'Lakeside Manor',
          'Amerigroup'
        ),
        rowTemplate(
          18,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Pyke, Jerome',
          '74636374',
          'St. Barnabas Home',
          'Cigna'
        ),
        rowTemplate(
          19,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Queen, Albert',
          '38475656',
          'Long Term Care Center of America',
          'UnitedHealth'
        ),
        rowTemplate(
          20,
          ['Missing Form (PL1)', 'LTCMI Alert (Denied)'],
          'Zuvia, Mustafa',
          '54736334',
          'Mountain Peace North',
          'Molina'
        )
      ]
    }
  },
  methods: {
    toggleTooltip(ref) {
      this.$refs[ref][0].toggle()
    }
  },
  mounted() {
    this.$eventHub.$emit('toggle-filter-badge')
  },
  beforeDestroy() {
    this.$eventHub.$emit('toggle-filter-badge')
  }
}
</script>

<style lang="scss" scoped>
.table-alerts {
  position: relative;
}
</style>
