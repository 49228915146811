import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    typeOfSideBySide: 'default'
  },
  mutations: {
    setTypeOfSideBySide(state, value) {
      state.typeOfSideBySide = value
    }
  }
})

export default store
