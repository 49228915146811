<template>
  <div class="item">
    <div class="item__icon">
      <slot name="icon"></slot>
    </div>
    <div>
      <div class="item__label" :class="{ 'item__label--bold': labelBold }">
        {{ label }}
      </div>
      <div v-if="value" class="item__value">
        {{ value }}
        <span class="item__value__unit" v-if="unit !== null">{{ unit }}</span>
      </div>
      <div v-else class="item__view-more">
        <span class="item__view-more__link">View more</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      required: false,
      default: null
    },
    labelBold: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  align-items: flex-start;
  color: #383874;
  display: flex;
  gap: 12px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 1600px) {
    gap: 8px;
  }

  &__icon {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 100px 80px rgba(108, 73, 172, 0.07),
      0 42px 33px rgba(108, 73, 172, 0.05), 0 22px 18px rgba(108, 73, 172, 0.04),
      0 13px 10px rgba(108, 73, 172, 0.035), 0 7px 5px rgba(108, 73, 172, 0.03),
      0 3px 2px rgba(108, 73, 172, 0.019);
    display: flex;
    height: 46px;
    justify-content: center;
    width: 46px;
  }

  &__label {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    @media only screen and (max-width: 1600px) {
      font-size: 14px;
    }

    &--bold {
      color: #383874;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.44px;
      line-height: 24px;

      @media only screen and (max-width: 1600px) {
        font-size: 16px;
      }
    }
  }

  &__value {
    font-size: 22px;
    font-weight: 600;

    @media only screen and (max-width: 1600px) {
      font-size: 20px;
    }

    &__unit {
      font-weight: 300;
    }
  }

  &__view-more {
    &__link {
      color: #383874;
      cursor: pointer;
      font-size: 14px;
      line-height: 36px / 2;
      text-decoration-line: underline;
    }
  }
}
</style>
