<template>
  <div class="tooltip" v-if="isVisible" v-click-outside="close">
    <div class="tooltip__item">
      RUG Level Mismatch (Amerigroup)
      <div>
        <button class="tooltip__item__button">View</button>
        <button class="tooltip__item__button" @click="$emit('toggle')">
          Dismiss
        </button>
      </div>
    </div>
    <div class="tooltip__item">
      Applied Income Mismatch (PCC)
      <div>
        <button class="tooltip__item__button">View</button>
        <button class="tooltip__item__button" @click="$emit('toggle')">
          Dismiss
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible
    },
    close() {
      this.$emit('close')
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.34);
  left: 0;
  position: absolute;
  text-align: left;
  top: 28px;
  width: 405px;
  z-index: 2;

  &__item {
    color: #000;
    display: flex;
    font-size: 14px;
    height: 46px;
    justify-content: space-between;
    line-height: 24px;
    padding: 11px 14px;

    &__button {
      background: #fff;
      border: 1px solid #adb1b8;
      border-radius: 4px;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      color: #585c64;
      cursor: pointer;
      height: 26px;
      margin-left: 14px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #dadada;
    }
  }
}
</style>
