<template>
  <div>
    <ClaimsBar />
    <TableSwitcher v-model="activeTable" :tabs="tabs" />
    <Transition name="fade" mode="out-in">
      <Component v-if="activeTableComponent" :is="activeTableComponent" />
    </Transition>
  </div>
</template>

<script>
import ClaimsBar from '@/components/widgets/ClaimsBar'
import TableSwitcher from '@/components/widgets/TableSwitcher'
import ReadyTable from '@/components/tables/ReadyTable'
import HeldTable from '@/components/tables/HeldTable'
import PaidTable from '@/components/tables/PaidTable'
import RejectsDenialsTable from '@/components/tables/RejectsDenialsTable'

export default {
  components: {
    ClaimsBar,
    TableSwitcher
  },
  data() {
    return {
      activeTable: 'ready',
      tabs: [
        {
          id: 'ready',
          label: 'Ready'
        },
        {
          id: 'held',
          label: 'Held',
          badgeContent: '72'
        },
        {
          id: 'ack-pending',
          label: 'Pending Ack',
          disabled: true
        },
        {
          id: 'payment-pending',
          label: 'Payment Pending',
          disabled: true
        },
        {
          id: 'paid',
          label: 'Paid'
        },
        {
          id: 'rejects',
          label: 'Rejects & Denials'
        },
        {
          id: 'appeals',
          label: 'Appeals & Reconsiderations',
          disabled: true
        }
      ]
    }
  },
  computed: {
    activeTableComponent() {
      switch (this.activeTable) {
        case 'ready':
          return ReadyTable
        case 'held':
          return HeldTable
        case 'paid':
          return PaidTable
        case 'rejects':
          return RejectsDenialsTable
        default:
          return null
      }
    }
  }
}
</script>
