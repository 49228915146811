<template>
  <BaseWidget>
    <p class="widget__title">AR Aging</p>
    <p class="widget__value">
      $11,550,308
      <span class="widget__subtext">
        0-30 days
      </span>
    </p>
    <p class="widget__value">
      $2,618,072
      <span class="widget__subtext">
        31-60 days
      </span>
    </p>
    <p class="widget__value">
      $1,386,021
      <span class="widget__subtext">
        61-90 days
      </span>
    </p>
    <p class="widget__value">
      $500,503
      <span class="widget__subtext">
        91+ days
      </span>
    </p>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'

export default {
  components: { BaseWidget }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px 30px;

  &__value {
    border-bottom: solid #dbdff1 1px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin: 4px 0;
    padding: 8px 0;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__subtext {
    margin-left: 8px;
  }
}
</style>
