<script>
import { Bar } from 'vue-chartjs'

require('./chartJsRoundedBars')

export default {
  extends: Bar,
  props: {
    styled: {
      type: Object,
      default: () => ({
        height: `424px`,
        position: 'relative'
      })
    }
  },
  mounted() {
    const barCtx = document.getElementById('bar-chart').getContext('2d')

    var barBackground = barCtx.createLinearGradient(0, 0, 0, 600)
    barBackground.addColorStop(0, '#0D3556')
    barBackground.addColorStop(0.5, '#521B7C')

    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: [
          'Jan',
          'Jan-2/3',
          'Jan-3/3',
          'Feb',
          'Feb-2/3',
          'Feb-3/3',
          'Mar',
          'Mar-2/3',
          'Mar-3/3',
          'Apr',
          'Apr-2/3',
          'Apr-3/3',
          'May',
          'May-2/3',
          'May-3/3',
          'Jun',
          'Jun-2/3',
          'Jun-3/3',
          'Jul',
          'Jul-2/3',
          'Jul-3/3',
          'Aug',
          'Aug-2/3',
          'Aug-3/3',
          'Sep',
          'Sep-2/3',
          'Sep-3/3',
          'Oct',
          'Oct-2/3',
          'Oct-3/3',
          'Nov',
          'Nov-2/3',
          'Nov-3/3',
          'Dec',
          'Dec-2/3',
          'Dec-3/3'
        ],
        datasets: [
          {
            backgroundColor: barBackground,
            width: 16,
            data: [
              0.6,
              0.8,
              1.2,
              1.4,
              1.5,
              1.6,
              1.6,
              1.6,
              1.6,
              1.5,
              1.4,
              1.3,
              1.2,
              1.1,
              1,
              1,
              1,
              0.9,
              0.8,
              0.7,
              0.5,
              0.4,
              0.6,
              0.9,
              1.3,
              1.2,
              1.1,
              1,
              1
            ]
          }
        ]
      },
      {
        borderSkipped: 'right',
        responsive: true,
        maintainAspectRatio: false,
        cornerRadius: 100,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem) {
              let label = tooltipItem[0].xLabel || ''
              const parts = label.split('-')
              label = parts[0]

              switch (label) {
                case 'Jan':
                  label = 'January'
                  break
                case 'Feb':
                  label = 'February'
                  break
                case 'Mar':
                  label = 'March'
                  break
                case 'Apr':
                  label = 'April'
                  break
                case 'May':
                  label = 'May'
                  break
                case 'Jun':
                  label = 'June'
                  break
                case 'Jul':
                  label = 'July'
                  break
                case 'Aug':
                  label = 'August'
                  break
                case 'Sep':
                  label = 'September'
                  break
                case 'Oct':
                  label = 'October'
                  break
                case 'Nov':
                  label = 'November'
                  break
                case 'Dec':
                  label = 'December'
                  break
              }

              return label
            },
            label: function(tooltipItem) {
              return tooltipItem.yLabel < 1
                ? `${parseInt(1000 * tooltipItem.yLabel)}k`
                : `${tooltipItem.yLabel}m`
            }
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                zeroLineColor: '#F1F1F5',
                color: '#F1F1F5',
                drawBorder: false
              },
              ticks: {
                userCallback: function(item, index) {
                  if (!(index % 3)) return item
                },
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                fontFamily: 'Inter',
                fontColor: '#383874',
                // fontSize: '16px', // Causes a font display bug.
                lineHeight: '24px',
                fontWeight: '300'
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                stepSize: 0.5,
                max: 2.5,
                callback: function(value) {
                  if (value === 0) {
                    return '0'
                  }

                  return value < 1 ? `${parseInt(1000 * value)}k` : `${value}m`
                },
                fontFamily: 'Inter',
                fontColor: '#383874',
                // fontSize: '16px', // Causes a font display bug.
                lineHeight: '24px',
                fontWeight: '300'
              }
            }
          ]
        }
      }
    )
  }
}
</script>
