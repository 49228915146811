<template>
  <div>
    <ArAgingBar />
    <TableSwitcher v-model="activeTable" :tabs="[]" />
    <ArAgingTable />
  </div>
</template>

<script>
import ArAgingBar from '@/components/widgets/ArAgingBar'
import TableSwitcher from '@/components/widgets/TableSwitcher'
import ArAgingTable from '@/components/tables/ArAgingTable'

export default {
  components: {
    ArAgingBar,
    TableSwitcher,
    ArAgingTable
  },
  data() {
    return {
      activeTable: 'ar-aging'
    }
  }
}
</script>
