<template>
  <BaseTable :rows="rows" :selectable="false">
    <template slot="header">
      <template>
        <td>Resident</td>
        <td>Patient ID</td>
        <td>Facility Name</td>
        <td>Last Viewed</td>
        <td>Last Updated</td>
        <td>Payor</td>
        <td>CoreCare Status</td>
      </template>
    </template>
    <template slot="row" slot-scope="{ row }">
      <template v-for="(cell, j) in row.data">
        <td v-if="cell.dynamic && cell.type === 'status-ok'" :key="j">
          <button class="table-button table-button--offset-left">
            OK
          </button>
        </td>
        <td v-else-if="cell.type === 'resident'" :key="j">
          <ResidentTooltip
            :ref="`resident-tooltip-${row.id}`"
            :label="cell.value"
          />
        </td>
        <td v-else-if="cell.type === 'facility'" :key="j">
          <FacilityTooltip
            :ref="`resident-tooltip-${row.id}`"
            :label="cell.value"
          />
        </td>
        <td v-else :key="j">
          <span>
            {{ cell }}
          </span>
        </td>
      </template>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable'
import ResidentTooltip from '@/components/tooltips/ResidentTooltip'
import FacilityTooltip from '@/components/tooltips/FacilityTooltip'

const rowTemplate = (id, patientName, medicaidId, facilityName, payorName) => ({
  id,
  data: [
    {
      value: patientName,
      type: 'resident'
    },
    medicaidId,
    {
      value: facilityName,
      type: 'facility'
    },
    'Yesterday',
    '01/04/2020',
    payorName,
    {
      dynamic: true,
      type: 'status-ok'
    }
  ]
})

export default {
  components: {
    BaseTable,
    ResidentTooltip,
    FacilityTooltip
  },
  data() {
    return {
      rows: [
        rowTemplate(
          1,
          'Vitela, Francisco',
          '36356184',
          'Lakeside Manor',
          'Amerigroup'
        ),
        rowTemplate(
          2,
          'Morrison, Lois',
          '59756664',
          'St. Barnabas Home',
          'Cigna'
        ),
        rowTemplate(
          3,
          'Acosta, Porfirio',
          '85305638',
          'Long Term Care Center of America',
          'UnitedHealth'
        ),
        rowTemplate(
          4,
          'Blanton, James',
          '58357574',
          'Mountain Peace North',
          'Amerigroup'
        ),
        rowTemplate(5, 'Black, Harry', '29182636', 'Lakeside Manor', 'Cigna'),
        rowTemplate(
          6,
          'Cannada, Dorothy',
          '32465834',
          'St. Barnabas Home',
          'Amerigroup'
        ),
        rowTemplate(
          7,
          'Cave, Randy',
          '12345372',
          'Long Term Care Center of America',
          'UnitedHealth'
        ),
        rowTemplate(
          8,
          'Ferrell, Jerry',
          '93752649',
          'Lakeside Manor',
          'Molina'
        ),
        rowTemplate(
          9,
          'Green, Hattie',
          '29357574',
          'St. Barnabas Home',
          'Amerigroup'
        ),
        rowTemplate(
          10,
          'Gwin, Anita',
          '19284465',
          'Long Term Care Center of America',
          'Amerigroup'
        ),
        rowTemplate(
          11,
          'Gunkel, Michael',
          '49274754',
          'Lakeside Manor',
          'Cigna'
        ),
        rowTemplate(
          12,
          'Grissom, Douglas',
          '29346523',
          'St. Barnabas Home',
          'Molina'
        ),
        rowTemplate(
          13,
          'Glee, Marissa',
          '23734646',
          'Long Term Care Center of America',
          'Amerigroup'
        ),
        rowTemplate(
          14,
          'Griffin, Louis',
          '123126722',
          'Lakeside Manor',
          'UnitedHealth'
        ),
        rowTemplate(
          15,
          'McAlpha, John',
          '124129043',
          'St. Barnabas Home',
          'Amerigroup'
        ),
        rowTemplate(
          16,
          'Merryweather, Lisa',
          '12384946',
          'Long Term Care Center of America',
          'Cigna'
        ),
        rowTemplate(
          17,
          'Norman, Paul',
          '92744655',
          'Lakeside Manor',
          'Amerigroup'
        ),
        rowTemplate(
          18,
          'Pyke, Jerome',
          '74636374',
          'St. Barnabas Home',
          'Cigna'
        ),
        rowTemplate(
          19,
          'Queen, Albert',
          '38475656',
          'Long Term Care Center of America',
          'UnitedHealth'
        ),
        rowTemplate(
          20,
          'Zuvia, Mustafa',
          '54736334',
          'Mountain Peace North',
          'Molina'
        )
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
tr td:last-of-type .table-button {
  margin-left: auto;
  margin-right: auto;
}
</style>
