<template>
  <div :class="['sidebar', { 'sidebar--visible': showSidebar }]">
    <div class="sidebar__header">
      <img
        src="@/assets/images/close.svg"
        class="sidebar__header__close-button"
        @click="showSidebar = false"
      />
      <img
        src="@/assets/images/core-care-logo-white.svg"
        class="sidebar__header__logo"
      />
    </div>

    <div class="sidebar__menu" @click.stop="showSidebarMenu = !showSidebarMenu">
      {{
        $route.path.includes('end-user') ? 'End User Dashboard' : $route.name
      }}
      <img src="@/assets/images/caret-double.svg" />
      <div class="sidebar__menu__options" v-if="showSidebarMenu">
        <a class="sidebar__menu__option" href="/executive">
          Executive Dashboard
        </a>
        <a class="sidebar__menu__option" href="/manager">Manager Dashboard</a>
        <a class="sidebar__menu__option" href="/end-user">End User Dashboard</a>
      </div>
    </div>

    <div class="sidebar__divider"></div>

    <p class="sidebar__category">Admin Tools</p>

    <a href="/" class="sidebar__link">
      <img src="@/assets/images/analytics.svg" />
      Analytics
    </a>
    <a href="/" class="sidebar__link">
      <img src="@/assets/images/reports.svg" />
      Reports
    </a>
    <a href="/" class="sidebar__link">
      <img src="@/assets/images/notifications.svg" />
      Notifications
      <span class="sidebar__link__notifications">2</span>
    </a>
    <a href="/" class="sidebar__link">
      <img src="@/assets/images/tasks.svg" />
      Tasks
    </a>

    <div class="sidebar__divider"></div>

    <p class="sidebar__category">Workviews</p>
    <a href="/end-user" class="sidebar__link">My Claims</a>
    <a href="/end-user/ar-aging" class="sidebar__link">My AR Aging</a>
    <a href="/end-user/residents" class="sidebar__link">Residents</a>
    <a href="/end-user/benefits-eligibility" class="sidebar__link">
      Benefits & Eligibility
    </a>
    <a class="sidebar__link">Saved Residents</a>
    <a class="sidebar__link">Missing Money</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSidebarMenu: false,
      showSidebar: false
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  background: #081f32;
  height: 100%;
  left: -260px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  transition: left 0.2s ease;
  width: 260px;
  z-index: 10;

  &--visible {
    left: 0;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 12px;

    &__close-button {
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background: #15344e;
      }
    }

    &__logo {
      margin-left: 36px;
    }
  }

  &__divider {
    background: #637a8b;
    height: 0.5px;
    margin: 16px;
  }

  &__category {
    color: #b3c4d2;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
    margin-left: 15px;
  }

  &__link {
    border-radius: 8px;
    color: #fff;
    display: flex;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    gap: 12px;
    line-height: 20px;
    margin: 0 13px;
    padding: 15px 12px;
    text-decoration: none;
    transition: background 0.3s ease;

    &__notifications {
      background: #7827e4;
      border-radius: 50%;
      font-size: 10px;
      height: 20px;
      margin-left: auto;
      text-align: center;
      width: 20px;
    }

    &:hover {
      background: #1c405e;
    }
  }

  &__menu {
    background: #1c405e;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    margin: 16px 13px 0;
    padding: 15px 21px;
    position: relative;

    &__options {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(58, 58, 68, 0.16),
        0 8px 16px rgba(90, 91, 106, 0.16);
      left: 4px;
      padding: 8px;
      position: absolute;
      top: 52px;
      z-index: 4;
    }

    &__option {
      border-radius: 4px;
      color: #19191d;
      display: block;
      line-height: 24px;
      padding: 8px 12px;
      text-decoration: none;
      transition: background 0.3s ease;
      width: 210px;

      &:hover {
        background: #ededf0;
      }
    }
  }
}
</style>
