<template>
  <div class="residents-filters">
    <div class="residents-filters__tabs">
      <div
        class="residents-filters__tabs__tab residents-filters__tabs__tab--active"
      >
        Filters
      </div>
      <div class="residents-filters__tabs__tab">
        Report Builder
      </div>
    </div>

    <div class="residents-filters__content">
      <p class="residents-filters__content__title">
        Facilities (37)
      </p>

      <InputWithIcon>
        <input placeholder="Search facilities" type="text" />

        <template slot="icon">
          <img src="@/assets/images/magnifying-glass-2.svg" />
        </template>
      </InputWithIcon>

      <BaseCheckbox variant="indigo" label="Lakeside Manor" />
      <BaseCheckbox variant="indigo" label="St. Barnabas Home" />
      <BaseCheckbox variant="indigo" label="Long Term Care Center of America" />
      <BaseCheckbox variant="indigo" label="Mountain Peace North" />

      <p class="residents-filters__content__link">See all</p>

      <p class="residents-filters__content__title">
        CoreCare Status
      </p>

      <BaseCheckbox variant="indigo" label="OK" />
      <BaseCheckbox variant="indigo" label="Review" />

      <p class="residents-filters__content__title">
        Payor
      </p>

      <BaseCheckbox variant="indigo" label="TMHP" />
      <BaseCheckbox variant="indigo" label="Amerigroup" />
      <BaseCheckbox variant="indigo" label="Superior" />
      <BaseCheckbox variant="indigo" label="Molina" />
      <BaseCheckbox variant="indigo" label="Cigna" />
      <BaseCheckbox variant="indigo" label="UnitedHealth" />

      <p class="residents-filters__content__title">
        Medicaid ID Status
      </p>

      <BaseCheckbox variant="indigo" label="New" />
      <BaseCheckbox variant="indigo" label="Blank" />
      <BaseCheckbox variant="indigo" label="Pending (+)" />
      <BaseCheckbox variant="indigo" label="No Medicaid (N)" />
      <BaseCheckbox variant="indigo" label="Present" />

      <p class="residents-filters__content__title">
        Alert Types
      </p>

      <BaseCheckbox variant="indigo" label="Retro Additions" />
      <BaseCheckbox variant="indigo" label="Retro Changes" />
      <BaseCheckbox variant="indigo" label="Payor Change" />
      <BaseCheckbox variant="indigo" label="RUG Split" />
      <BaseCheckbox variant="indigo" label="RUG Gap" />
      <BaseCheckbox variant="indigo" label="Assessment Upcoming" />
      <Transition name="fade">
        <div v-if="showAllAlertTypes">
          <BaseCheckbox variant="indigo" label="Assessment In Progress" />
          <BaseCheckbox variant="indigo" label="MESAV Mismatch" />
          <BaseCheckbox
            variant="indigo"
            label="Invalid Service Authorization"
          />
          <BaseCheckbox variant="indigo" label="Missing Forms" />
          <BaseCheckbox variant="indigo" label="Missing Medicaid Eligibility" />
        </div>
      </Transition>

      <p
        class="residents-filters__content__link"
        @click="showAllAlertTypes = !showAllAlertTypes"
      >
        {{ showAllAlertTypes ? 'See less' : 'See all' }}
      </p>

      <p class="residents-filters__content__title">
        Service Authorizations
      </p>

      <BaseCheckbox variant="indigo" label="Wheelchair" />
      <BaseCheckbox variant="indigo" label="DME" />
      <BaseCheckbox variant="indigo" label="Vent" />
      <BaseCheckbox variant="indigo" label="PASRR" />
      <BaseCheckbox variant="indigo" label="Hospice" />
    </div>
  </div>
</template>

<script>
import InputWithIcon from '@/components/widgets/InputWithIcon'
import BaseCheckbox from '@/components/widgets/BaseCheckbox'

export default {
  components: {
    InputWithIcon,
    BaseCheckbox
  },
  data() {
    return {
      showAllAlertTypes: false
    }
  }
}
</script>

<style lang="scss" scoped>
.app-checkbox {
  display: flex;
  margin-bottom: 8px;
  white-space: nowrap;
}

.residents-filters {
  background: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  height: calc(100vh - 48px);
  overflow-y: scroll;
  padding-bottom: 85px;
  position: fixed;
  top: 48px;
  width: 320px;
  z-index: 1;

  &__content {
    padding: 0 26px 26px 26px;

    &__title {
      border-bottom: 1px solid #adb1b8;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin: 24px 0 12px 0;
      padding-bottom: 8px;
    }

    &__subtitle {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    &__link {
      color: #5813da;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 25px;
    }
  }

  &__tabs {
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    padding: 0 26px;
    position: relative;
    width: 100%;

    &__tab {
      color: #0d283e;
      cursor: pointer;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.1px;
      line-height: 16px;
      margin-right: 45px;
      padding: 0 13px 13px 13px;
      position: relative;
      text-align: center;
      white-space: nowrap;

      &--active {
        font-weight: 500;

        &::after {
          background-image: url('../../assets/images/tab-active.svg');
          background-size: 100% 100%;
          bottom: 0;
          content: '';
          cursor: inherit;
          height: 2px;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }
  }
}
</style>
