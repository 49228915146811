<template>
  <RadialProgressBar
    :diameter="diameter"
    :completed-steps="progress"
    :total-steps="100"
    inner-stroke-color="#DBDFF1"
    :inner-stroke-width="3"
    :stroke-width="5"
    stroke-linecap="round"
    :start-color="color"
    :stop-color="color"
  >
    <slot />
  </RadialProgressBar>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

export default {
  components: { RadialProgressBar },
  props: {
    color: {
      type: String,
      required: true
    },
    progress: {
      type: Number,
      required: true
    },
    diameter: {
      type: Number,
      default: 64
    }
  }
}
</script>
