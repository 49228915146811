<template>
  <div class="tooltip" v-if="isVisible" v-click-outside="close">
    <template v-if="variant === 'a'">
      <div class="tooltip__item">
        MDS Assessment In-Progress
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
    </template>
    <template v-if="variant === 'b'">
      <div class="tooltip__item">
        Missing Form (3618)
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
      <div class="tooltip__item">
        RUG Rate Mismatch
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
    </template>
    <template v-if="variant === 'c'">
      <div class="tooltip__item">
        MCO Change
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
      <div class="tooltip__item">
        RUG Split
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
    </template>
    <template v-if="variant === 'd'">
      <div class="tooltip__item">
        Lapsed Service Auth
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
      <div class="tooltip__item">
        Payor Mismatch
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
    </template>
    <template v-if="variant === 'e'">
      <div class="tooltip__item">
        Lapsed Medicaid Eligibility
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
      <div class="tooltip__item">
        Applied Income Mismatch
        <div>
          <button class="tooltip__item__button">View Issue</button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
    </template>
    <template v-if="variant === 'florina'">
      <div class="tooltip__item">
        RUG Level Mismatch (Amerigroup)
        <div>
          <button
            class="tooltip__item__button"
            @click="$eventHub.$emit('toggle-side-by-side-florina')"
          >
            View Issue
          </button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
      <div class="tooltip__item">
        Applied Income Mismatch (PCC)
        <div>
          <button
            class="tooltip__item__button"
            @click="$eventHub.$emit('toggle-side-by-side-florina')"
          >
            View Issue
          </button>
          <button class="tooltip__item__button">Notify</button>
          <button class="tooltip__item__button">Edit</button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible
    },
    close() {
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.34);
  left: 0;
  position: absolute;
  text-align: left;
  top: 22px;
  width: 540px;
  z-index: 2;

  &__item {
    color: #000;
    display: flex;
    font-size: 14px;
    height: 46px;
    justify-content: space-between;
    line-height: 24px;
    padding: 11px 14px;

    &__button {
      background: #fff;
      border: 1px solid #adb1b8;
      border-radius: 4px;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      color: #585c64;
      cursor: pointer;
      height: 26px;
      margin-left: 14px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #dadada;
    }
  }
}
</style>
