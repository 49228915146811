<template>
  <div
    class="table-switcher"
    :class="{ 'table-switcher--no-border': tabs.length === 0 }"
  >
    <div class="table-switcher__tabs">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        class="table-switcher__tabs__tab"
        :class="{
          'table-switcher__tabs__tab--active': value === tab.id
        }"
        @click="changeActive(tab)"
      >
        <span v-if="tab.badgeContent" class="table-switcher__tabs__tab__badge">
          {{ tab.badgeContent }}
        </span>
        <span>{{ tab.label }}</span>
      </div>
    </div>
    <div v-if="showFilters" class="table-switcher__filters">
      <div class="table-switcher__filters__button">
        <img
          class="table-switcher__filters__button__icon"
          src="@/assets/images/filters.svg"
          alt="Filters"
        />
        <span class="table-switcher__filters__button__text">Filter</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    },
    showFilters: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    changeActive(tab) {
      if (tab.disabled) {
        return
      }

      this.$emit('input', tab.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-switcher {
  align-items: center;
  border-bottom: solid 1px #9f9ba7;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  padding: 0 11px;
  width: 100%;

  &--no-border {
    border: none;
  }

  &__tabs {
    display: flex;
    flex-direction: row;

    &__tab {
      align-items: center;
      color: #0d283e;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      justify-content: center;
      letter-spacing: 0.1px;
      line-height: 16px;
      padding: 10px 24px;
      position: relative;
      transition: opacity 0.2s ease;

      &::after {
        background-image: url('../../assets/images/tab-active.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        bottom: -1px;
        content: '';
        height: 4px;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity 0.2s ease;
        width: 100%;
      }

      &--active {
        font-weight: bold;

        &::after {
          opacity: 1;
        }
      }

      &__badge {
        background: #081f32;
        border-radius: 99px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.018em;
        line-height: 143%;
        margin-right: 6px;
        padding: 0 7.5px;
      }
    }
  }

  &__filters {
    margin-left: auto;

    &__button {
      align-items: center;
      display: flex;

      &__icon {
        height: 16px;
        margin-right: 8px;
        width: 16px;
      }

      &__text {
        color: #1d1c1d;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
</style>
