<template>
  <BaseWidget :no-effect="true">
    <p class="widget__title">Team Metrics</p>
    <div class="widget__inner">
      <div class="table">
        <div class="table__head-row">
          <div class="table__head-cell">Employee</div>
          <div class="table__head-cell">
            <sup>*</sup>
            <span>DSO</span>
          </div>
          <div class="table__head-cell">Claims</div>
          <div class="table__head-cell">
            <sup>*</sup>
            <span>AR Aging (61+)</span>
          </div>
        </div>
        <div class="table__subhead-row">
          <sup>*</sup>
          <span>30 days</span>
        </div>
        <div class="table__body">
          <div class="table__row">
            <div class="table__cell">David Rose</div>
            <div class="table__cell">22 days</div>
            <div class="table__cell">$350,643</div>
            <div class="table__cell">$199,789</div>
          </div>
          <div class="table__row table__row--active">
            <div class="table__cell">Patti Lewis</div>
            <div class="table__cell">40 days</div>
            <div class="table__cell">$742,227</div>
            <div class="table__cell">$239,878</div>
          </div>
          <div class="table__row">
            <div class="table__cell">Elaine Klein</div>
            <div class="table__cell">30 days</div>
            <div class="table__cell">$402,232</div>
            <div class="table__cell">$189,329</div>
          </div>
          <div class="table__row">
            <div class="table__cell">James Tolliver</div>
            <div class="table__cell">28 days</div>
            <div class="table__cell">$362,964</div>
            <div class="table__cell">$137,662</div>
          </div>
          <div class="table__row">
            <div class="table__cell">Reina Terry</div>
            <div class="table__cell">35 days</div>
            <div class="table__cell">$687,739</div>
            <div class="table__cell">$271,002</div>
          </div>
        </div>
      </div>
      <div class="chart">
        <div class="chart__inner">
          <img
            class="chart__image"
            src="@/assets/images/online-frequency.svg"
          />
          <div class="chart__labels">
            <div class="chart__labels__divider" />
            <div class="chart__labels__item">
              <span class="chart__labels__item__label">Last Login:</span>
              <span class="chart__labels__item__value">Yesterday</span>
            </div>
            <div class="chart__labels__item">
              <span class="chart__labels__item__label">Location:</span>
              <span class="chart__labels__item__value">Office laptop</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'

export default {
  components: { BaseWidget }
}
</script>

<style lang="scss" scoped>
.widget {
  overflow: hidden;
  padding: 24px 20px 0 30px;
  // width: 816px;

  &__inner {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
}

.table {
  margin-right: 15px;
  max-height: 283px;
  overflow-y: scroll;
  padding-right: 10px;

  &__head-row {
    border-bottom: solid 1px #ddd;
    color: #9b9bb8;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
    width: 100%;
  }

  &__subhead-row {
    color: #9b9bb8;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 8px;
    margin-top: 5px;
    width: 100%;
  }

  &__head-cell {
    display: table-cell;
    padding: 0 6px 6px 6px;
    text-align: left;

    &:first-of-type {
      padding-left: 12px;
    }

    &:last-of-type {
      padding-right: 12px;
    }

    &:nth-of-type(1) {
      width: 149px;
    }

    &:nth-of-type(2) {
      width: 98px;
    }

    &:nth-of-type(3) {
      width: 81px;
    }

    &:nth-of-type(4) {
      width: 91px;
    }
  }

  &__body {
    padding-bottom: 10px;
    padding-left: 2px;
    padding-right: 3px;
  }

  &__row {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    position: relative;
    transition: 0.2s ease;
    // width: 432px;

    &--active {
      border: solid 2px #491d77; // In Figma we have a gradient, but it can be added later if really required. Some hacks need to be used.
    }

    &:hover {
      background-color: darken(#fff, 2%);
    }
  }

  &__cell {
    align-items: center;
    color: #323c47;
    display: flex;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 17px;
    padding: 19px 6px;

    @media only screen and (max-width: 1800px) {
      font-size: 13px;
      line-height: 16px;
    }

    &:first-of-type {
      padding-left: 12px;
    }

    &:last-of-type {
      padding-right: 12px;
    }

    &:nth-of-type(1) {
      width: 149px;
    }

    &:nth-of-type(2) {
      width: 98px;
    }

    &:nth-of-type(3) {
      width: 81px;
    }

    &:nth-of-type(4) {
      width: 91px;
    }
  }
}

.chart {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
  margin-left: auto;

  @media only screen and (max-width: 1800px) {
    max-width: 250px;
  }

  @media only screen and (max-width: 1700px) {
    max-width: 200px;
  }

  &__image {
    height: auto;
    width: 100%;
  }

  &__labels {
    &__divider {
      background-color: #ddd;
      height: 1px;
      margin-bottom: 12px;
      margin-top: 19px;
      width: 100%;
    }

    &__item {
      &__label {
        color: #9b9bb8;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 16px;
        text-transform: uppercase;
      }

      &__value {
        color: #323c47;
        font-size: 12px;
        letter-spacing: 0.01em;
        line-height: 15px;
      }
    }
  }
}
</style>
