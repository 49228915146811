<template>
  <BaseWidget>
    <div class="widget__column widget__column--left">
      <p class="widget__title">Daily Sales Outstanding</p>
      <p class="widget__value">
        24 days
        <span class="widget__percentage--up">
          <img class="widget__icon" src="@/assets/images/caret-up.svg" />
          10%
        </span>
      </p>
      <span class="widget__subtext">
        Compared to 27 days last month
      </span>
    </div>
    <div class="widget__column widget__column--right">
      <div class="widget__day-selector widget__day-selector--active">
        <span>30</span>
        <span class="widget__day-selector__days">days</span>
      </div>
      <div class="widget__day-selector">
        <span>60</span>
        <span class="widget__day-selector__days">days</span>
      </div>
      <div class="widget__day-selector">
        <span>90</span>
        <span class="widget__day-selector__days">days</span>
      </div>
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'

export default {
  components: { BaseWidget }
}
</script>

<style lang="scss" scoped>
.widget {
  display: flex;
  flex-direction: row;
  padding: 20px 10px 20px 30px;

  &__column {
    &--left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 220px;
    }

    &--right {
      width: 30px;
    }
  }

  &__day-selector {
    align-items: center;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    color: #383874;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    justify-content: center;
    letter-spacing: 0.4px;
    line-height: 16px;
    margin-bottom: 4px;
    padding: 4px;
    transition: 0.2s ease-in-out;
    width: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__days {
      font-size: 8px;
      font-weight: 300;
      letter-spacing: 0.4px;
      line-height: 16px;
      margin-top: -4px;
    }

    &:hover,
    &--active {
      background: #383874;
      border-radius: 5px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      color: #fff;
    }
  }

  &__icon {
    margin-right: 4px;
  }

  &__value {
    font-size: 34px;
    font-weight: 400;
    letter-spacing: -0.44px;
    line-height: 44px;
  }
}
</style>
