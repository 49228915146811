<template>
  <div class="residents-bar">
    <div class="residents-bar__row">
      <h1 class="residents-bar__title">Resident Portal</h1>
      <SummaryWidget label="Analytics" label-bold>
        <template #icon>
          <img src="@/assets/images/graph.svg" />
        </template>
      </SummaryWidget>
    </div>

    <p class="residents-bar__label">Quick filters</p>

    <div class="residents-bar__quick-filters">
      <div class="residents-bar__quick-filter">
        <span class="residents-bar__quick-filter__amount">28</span>
        Missing Service Authorizations
      </div>
      <div class="residents-bar__quick-filter">
        <span class="residents-bar__quick-filter__amount">92</span>
        Assessments in-Progress
      </div>
      <div class="residents-bar__quick-filter">
        <span class="residents-bar__quick-filter__amount">32</span>
        Payor Changes
      </div>
      <div class="residents-bar__quick-filter">
        <span class="residents-bar__quick-filter__amount">115</span>
        MESAV Mismatches
      </div>
    </div>

    <p class="residents-bar__label">Filtered results</p>
    <div
      class="residents-bar__active-filter"
      ref="filteredResults"
      v-if="isVisible"
    >
      Needs Review (253)
      <span class="close">&times;</span>
    </div>
  </div>
</template>

<script>
import SummaryWidget from '@/components/widgets/SummaryWidget'

export default {
  components: {
    SummaryWidget
  },
  data() {
    return {
      isVisible: false
    }
  },
  created() {
    this.$eventHub.$on('toggle-filter-badge', () => {
      this.isVisible = !this.isVisible
    })
  },
  beforeDestroy() {
    this.$eventHub.$off('toggle-filter-badge')
  }
}
</script>

<style lang="scss" scoped>
.residents-bar {
  margin-bottom: 41px;

  &__row {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: #081f32;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.218182px;
    line-height: 34px;
    margin-bottom: 40px;
    margin-top: 35px;
  }

  &__label {
    color: #0d283e;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  &__quick-filters {
    display: flex;
    gap: 10px;
    margin-bottom: 46px;
  }

  &__quick-filter {
    background: #fff;
    border-radius: 33px;
    box-shadow: 0 41.7776px 33.4221px rgba(108, 73, 172, 0.0503198),
      0 22.3363px 17.869px rgba(108, 73, 172, 0.0417275),
      0 12.5216px 10.0172px rgba(108, 73, 172, 0.035),
      0 6.6501px 5.32008px rgba(108, 73, 172, 0.0282725),
      0 2.76726px 2.21381px rgba(108, 73, 172, 0.0196802);
    color: #081f32;
    flex-grow: 1;
    font-size: 14px;
    line-height: 150%;
    padding: 7px 10px;

    &__amount {
      background: #081f32;
      border-radius: 99px;
      color: #f7f7fa;
      font-size: 14px;
      font-weight: 500;
      line-height: 143%;
      padding: 2px 7px;
    }
  }

  &__active-filter {
    background: #081f32;
    border: 1px solid #081f32;
    border-radius: 99px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: 50012px;
    line-height: 126%;
    padding: 4px 10px;

    .close {
      font-size: 16px;
      margin-left: 8px;
    }
  }

  .item {
    margin-right: 120px;
  }
}
</style>
