<template>
  <BaseTable :rows="rows" :selectable="false">
    <template slot="header">
      <template>
        <td>UB-04</td>
        <td>Date Submitted</td>
        <td>Date Paid</td>
        <td class="align-left">Resident</td>
        <td class="align-left">Facility Name</td>
        <td>Claim Amount</td>
        <td>Expected Payment</td>
        <td>Payment Received</td>
        <td>Difference</td>
        <td>Payor Remit</td>
      </template>
    </template>
    <template slot="row" slot-scope="{ row }">
      <template>
        <td v-for="(cell, j) in row.data" :key="j">
          <div
            v-if="cell.dynamic && cell.type === 'view-edit'"
            style="text-align: center"
          >
            <button
              class="table-button table-button--hover-primary"
              @click="$eventHub.$emit('toggle-claim-panel')"
            >
              View/Edit
            </button>
          </div>
          <div
            v-else-if="cell.dynamic && cell.type === 'view-with-icon'"
            style="text-align: center"
          >
            <button class="table-button">
              <img class="view-icon" src="@/assets/images/view-icon.svg" />
              <span>View</span>
            </button>
          </div>
          <div v-else-if="cell.type === 'resident'">
            <ResidentTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </div>
          <div v-else-if="cell.type === 'facility'">
            <FacilityTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </div>
          <div
            v-else-if="typeof cell === 'string' && cell.startsWith('$')"
            style="text-align: center"
          >
            {{ cell }}
          </div>
          <div
            v-else-if="cell.type === 'difference'"
            class="difference-value"
            :class="{
              'difference-value--negative':
                cell.value && cell.value.startsWith('-'),
              'difference-value--neutral': cell.value === 0
            }"
          >
            {{ cell.value === 0 ? '--' : cell.value }}
          </div>
          <span v-else>
            {{ cell }}
          </span>
        </td>
      </template>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable'
import ResidentTooltip from '@/components/tooltips/ResidentTooltip'
import FacilityTooltip from '@/components/tooltips/FacilityTooltip'
import dollarVariantVariator from '@/variators/dollarValueVariator'

const rowTemplate = (id, fields) => ({
  id,
  data: [
    {
      dynamic: true,
      type: 'view-edit'
    },
    ...fields,
    {
      dynamic: true,
      type: 'view-with-icon'
    }
  ]
})

const dollarVariations = () => {
  const value = dollarVariantVariator(772, 2790, true)
  const random = Math.random() * 300
  const newValue = `$${new Intl.NumberFormat('en-US').format(
    Math.floor(value - random)
  )}`

  return [
    `$${new Intl.NumberFormat('en-US').format(value)}`,
    newValue,
    newValue,
    { type: 'difference', value: 0 }
  ]
}

export default {
  components: {
    BaseTable,
    ResidentTooltip,
    FacilityTooltip
  },
  data() {
    return {
      rows: [
        rowTemplate(1, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Vitela, Francisco',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          '$1,436.83',
          '$1,136.83',
          '$1,111.80',
          { type: 'difference', value: '-$25.03' }
        ]),
        rowTemplate(2, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Abeyta, Florina',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          '$1,436.83',
          '$1,058.59',
          '$1,053.55',
          { type: 'difference', value: '+$5.04' }
        ]),
        rowTemplate(3, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Acosta, Porfirio',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          '$1,436.83',
          '$2,035.76',
          '$1,734.16',
          { type: 'difference', value: '-$301.6' }
        ]),
        rowTemplate(4, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Blanton, James',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          ...dollarVariations()
        ]),
        rowTemplate(5, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Black, Harry',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          ...dollarVariations()
        ]),
        rowTemplate(6, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Cannada, Dorothy',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          ...dollarVariations()
        ]),
        rowTemplate(7, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Cave, Randy',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          ...dollarVariations()
        ]),
        rowTemplate(8, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Ferrell, Jerry',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          ...dollarVariations()
        ]),
        rowTemplate(9, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Green, Hattie',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          ...dollarVariations()
        ]),
        rowTemplate(10, [
          '10/26/2020',
          '11/3/2020',
          {
            value: 'Gwin, Anita',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          ...dollarVariations()
        ])
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  thead td:first-of-type {
    text-align: center;
  }

  td.align-left {
    padding-left: 12px !important;
    text-align: left;
  }
}

.view-icon {
  height: 15px;
  margin-right: 7px;
  width: 12.27px;
}

.difference-value {
  color: green;
  text-align: center;

  &--negative {
    color: #e71a0b;
  }

  &--neutral {
    color: inherit;
  }
}
</style>
