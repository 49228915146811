<template>
  <div>
    <div class="row">
      <div class="column column--filters">
        <ResidentsFilters />
      </div>
      <div class="column column--residents">
        <ResidentsBar />
        <TableSwitcher
          v-model="activeTable"
          :tabs="tabs"
          :show-filters="false"
        />
        <Transition name="fade" mode="out-in">
          <Component v-if="activeTableComponent" :is="activeTableComponent" />
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import ResidentsBar from '@/components/widgets/ResidentsBar'
import ResidentsFilters from '@/components/widgets/ResidentsFilters'
import TableSwitcher from '@/components/widgets/TableSwitcher'
import AllResidentsTable from '@/components/tables/AllResidentsTable'
import ResidentAlertsTable from '@/components/tables/ResidentAlertsTable'

export default {
  components: {
    ResidentsBar,
    ResidentsFilters,
    TableSwitcher
  },
  data() {
    return {
      activeTable: 'all-residents',
      tabs: [
        {
          id: 'all-residents',
          label: 'All'
        },
        {
          id: 'resident-alerts',
          label: 'Alerts'
        }
      ]
    }
  },
  computed: {
    activeTableComponent() {
      switch (this.activeTable) {
        case 'all-residents':
          return AllResidentsTable
        case 'resident-alerts':
          return ResidentAlertsTable
        default:
          return null
      }
    }
  },
  mounted() {
    // This is a work-around to save us time.
    const $content = document.getElementById('content')
    $content.classList.add('content--residents')
  },
  beforeDestroy() {
    // This is a work-around to save us time.
    const $content = document.getElementById('content')
    $content.classList.remove('content--residents')
  }
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;
}

.column {
  &--residents {
    padding-bottom: 28px;
    padding-left: 360px;
    width: 100%;
  }
}

.residents-bar {
  flex-grow: 1;
}
</style>
