<template>
  <BaseWidget>
    <p class="widget__title">Rates</p>

    <div class="widget__dropdown">
      Medicaid
      <img src="@/assets/images/caret-down.svg" />
    </div>

    <BaseProgressBar label="RAC" value="746" progress="80" />
    <BaseProgressBar label="RAA" value="633" progress="70" />
    <BaseProgressBar label="RAD" value="621" progress="60" />
    <BaseProgressBar label="SE2" value="428" progress="30" />
    <BaseProgressBar label="RAB" value="241" progress="25" />
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'
import BaseProgressBar from '@/components/widgets/BaseProgressBar'

export default {
  components: { BaseWidget, BaseProgressBar }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px 30px;
  // width: 290px;

  &__dropdown {
    margin: 7px 0 17px 0;
    width: 110px;
  }

  .progress-bar:not(:last-of-type) {
    margin-bottom: 13px;
  }
}
</style>
