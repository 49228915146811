<template>
  <div class="summary">
    <SummaryWidget label="Automated Tasks" value="41,978">
      <template #icon>
        <img src="@/assets/images/graph.svg" />
      </template>
    </SummaryWidget>

    <SummaryWidget label="Total Time Saved" value="4,734" unit="hours">
      <template #icon>
        <img src="@/assets/images/clock-rewind.svg" />
      </template>
    </SummaryWidget>

    <SummaryWidget label="Facilities Analyzed" value="37">
      <template #icon>
        <img src="@/assets/images/home.svg" />
      </template>
    </SummaryWidget>
  </div>
</template>

<script>
import SummaryWidget from './SummaryWidget'

export default {
  components: { SummaryWidget }
}
</script>

<style lang="scss" scoped>
.summary {
  display: flex;
  justify-content: space-between;
}
</style>
