<template>
  <table class="table">
    <thead class="table__header">
      <tr>
        <th colspan="6" class="table__header__th">
          Managed Care
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr>
        <th class="table__body__th">#</th>
        <th class="table__body__th">Add date</th>
        <th class="table__body__th">Begin date</th>
        <th class="table__body__th">End date</th>
        <th class="table__body__th">Line of business</th>
        <th class="table__body__th">Plan description</th>
      </tr>
      <tr v-if="data.length === 0">
        <td colspan="6">
          <div class="table__body__no-data">
            Amerigroup does not provide this data
          </div>
        </td>
      </tr>
      <tr v-for="(item, i) in data" :key="i">
        <td class="table__body__td">
          {{ i + 1 }}
        </td>
        <td class="table__body__td">
          {{ item.addDate }}
        </td>
        <td class="table__body__td">
          {{ item.beginDate }}
        </td>
        <td class="table__body__td">
          {{ item.endDate }}
        </td>
        <td class="table__body__td">
          {{ item.lineOfBusiness }}
        </td>
        <td class="table__body__td">
          {{ item.planDescription }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      instances: {
        default: {
          left: [
            {
              addDate: '04/09/2014',
              beginDate: '05/01/2014',
              endDate: '12/31/9999',
              lineOfBusiness: 'STRP',
              planDescription: 'AMERIGROUP'
            }
          ],
          right: []
        },
        florina: {
          left: [
            {
              addDate: '08/11/2015',
              beginDate: '07/01/2015',
              endDate: '12/31/9999',
              lineOfBusiness: 'STRP',
              planDescription: 'AMERIGROUP TEXAS, INC.'
            }
          ],
          right: []
        }
      }
    }
  },
  computed: {
    data() {
      return this.instances[this.$store.state.typeOfSideBySide][this.type]
    }
  }
}
</script>
