<template>
  <div class="benefits-bar">
    <h1 class="benefits-bar__title">Benefits and Eligibility</h1>
    <div class="benefits-bar__row">
      <QuickStatsWidget
        title="Pending IDs"
        class="benefits-bar__quick-stats"
        label="Total Pending IDs"
        value="115"
      />
      <QuickStatsWidget
        title="Upcoming Renewals"
        class="benefits-bar__quick-stats"
        label="Total Upcoming Renewals"
        value="225"
        :larger-bg="true"
      />
      <QuickStatsWidget
        title="Lapsed Benefits"
        class="benefits-bar__quick-stats"
        label="Total Lapsed Benefits"
        value="21"
        :larger-bg="true"
      />
      <div class="benefits-bar__view-more">
        <SummaryWidget label="Analytics" label-bold>
          <template #icon>
            <img src="@/assets/images/graph.svg" />
          </template>
        </SummaryWidget>
      </div>
    </div>
  </div>
</template>

<script>
import QuickStatsWidget from '@/components/widgets/QuickStatsWidget'
import SummaryWidget from '@/components/widgets/SummaryWidget'

export default {
  components: {
    QuickStatsWidget,
    SummaryWidget
  }
}
</script>

<style lang="scss" scoped>
.benefits-bar {
  margin-bottom: 55px;

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__title {
    color: #081f32;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.218182px;
    line-height: 34px;
    margin-bottom: 40px;
    margin-top: 35px;
  }

  &__quick-stats {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__view-more {
    align-items: center;
    display: flex;
    margin-left: 70px;
  }
}
</style>
