<template>
  <BaseWidget>
    <p class="widget__title">Projected Cash Flow</p>
    <p class="widget__value">
      $7,892,583
      <span class="widget__percentage--up">
        <img src="@/assets/images/caret-up.svg" />
        10%
      </span>
    </p>
    <span class="widget__subtext">
      Expected this week
    </span>
    <div class="cash-flow-items">
      <div class="cash-flow-items__item">
        <BaseRadialProgressBar
          class="cash-flow-items__item__progress"
          :diameter="56"
          :progress="22"
          color="#FFBA69"
        >
          <img
            class="cash-flow-items__item__progress__icon"
            src="@/assets/images/chart.svg"
            alt="Chart"
          />
          <span class="cash-flow-items__item__progress__value">
            <span>22</span>
            <sup>%</sup>
          </span>
        </BaseRadialProgressBar>
        <div class="cash-flow-items__item__value">
          <span class="cash-flow-items__item__value__label">Medicaid</span>
          <span class="cash-flow-items__item__value__amount">
            $1,732,552
            <span class="widget__percentage--up">
              <img src="@/assets/images/caret-up.svg" />
              12%
            </span>
          </span>
        </div>
      </div>
      <div class="cash-flow-items__item">
        <BaseRadialProgressBar
          class="cash-flow-items__item__progress"
          :diameter="56"
          :progress="68"
          color="#8375FD"
        >
          <img
            class="cash-flow-items__item__progress__icon"
            src="@/assets/images/chart.svg"
            alt="Chart"
          />
          <span class="cash-flow-items__item__progress__value">
            <span>68</span>
            <sup>%</sup>
          </span>
        </BaseRadialProgressBar>
        <div class="cash-flow-items__item__value">
          <span class="cash-flow-items__item__value__label">Medicare</span>
          <span class="cash-flow-items__item__value__amount">
            $5,390,841
            <span class="widget__percentage--up">
              <img src="@/assets/images/caret-up.svg" />
              15%
            </span>
          </span>
        </div>
      </div>
      <div class="cash-flow-items__item">
        <BaseRadialProgressBar
          class="cash-flow-items__item__progress"
          :diameter="56"
          :progress="7"
          color="#F06C8E"
        >
          <img
            class="cash-flow-items__item__progress__icon"
            src="@/assets/images/chart.svg"
            alt="Chart"
          />
          <span class="cash-flow-items__item__progress__value">
            <span>7</span>
            <sup>%</sup>
          </span>
        </BaseRadialProgressBar>
        <div class="cash-flow-items__item__value">
          <span class="cash-flow-items__item__value__label">Commercial</span>
          <span class="cash-flow-items__item__value__amount">
            $577,543
            <span class="widget__percentage--up">
              <img src="@/assets/images/caret-up.svg" />
              9%
            </span>
          </span>
        </div>
      </div>
      <div class="cash-flow-items__item">
        <BaseRadialProgressBar
          class="cash-flow-items__item__progress"
          :diameter="56"
          :progress="3"
          color="#081F32"
        >
          <img
            class="cash-flow-items__item__progress__icon"
            src="@/assets/images/chart.svg"
            alt="Chart"
          />
          <span class="cash-flow-items__item__progress__value">
            <span>3</span>
            <sup>%</sup>
          </span>
        </BaseRadialProgressBar>
        <div class="cash-flow-items__item__value">
          <span class="cash-flow-items__item__value__label">Private</span>
          <span class="cash-flow-items__item__value__amount">
            $192,192
            <span class="widget__percentage--up">
              <img src="@/assets/images/caret-up.svg" />
              4%
            </span>
          </span>
        </div>
      </div>
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'
import BaseRadialProgressBar from '@/components/helpers/BaseRadialProgressBar'

export default {
  components: { BaseWidget, BaseRadialProgressBar }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px 30px;
  // width: 290px;

  &__value {
    font-size: 34px;
    letter-spacing: -0.44px;
    line-height: 44px;
  }

  &__percentage--up {
    margin-left: 6px;
  }
}

.cash-flow-items {
  margin-top: 20px;

  &__item {
    border-top: solid 1px #dbdff1;
    display: flex;
    flex-direction: row;
    padding: 20px 0;

    &__progress {
      margin-right: 11px;

      ::v-deep .radial-progress-inner {
        display: flex;
        justify-content: flex-end;
      }

      &__icon {
        height: 11.79px;
        left: auto;
        position: absolute;
        right: auto;
        top: 12.53px;
        width: 11.79px;
      }

      &__value {
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 29px;
        margin: 0 11px 7px 11px;

        sup {
          font-size: 10.1112px;
          letter-spacing: 0.222447px;
          line-height: 16px;
          top: -0.65em;
        }
      }
    }

    &__value {
      display: flex;
      flex-direction: column;

      &__label {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.44px;
        line-height: 24px;
      }

      &__amount {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 32px;
      }
    }
  }
}
</style>
