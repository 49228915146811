<template>
  <BaseTable :rows="rows" :submitable="true" :accumulator-index="7">
    <template slot="header">
      <template>
        <td>UB-04</td>
        <td>CoreCare Status</td>
        <td>Claim #</td>
        <td>Dates of Service</td>
        <td class="align-left">Resident</td>
        <td class="align-left">Facility Name</td>
        <td>Claim Status</td>
        <td>Amount</td>
      </template>
    </template>
    <template slot="row" slot-scope="{ row }">
      <template>
        <td v-for="(cell, j) in row.data" :key="j">
          <button
            v-if="cell.dynamic && cell.type === 'view-edit'"
            class="table-button table-button--hover-primary"
            @click="$eventHub.$emit('toggle-claim-panel')"
          >
            View/Edit
          </button>
          <button
            v-else-if="cell.dynamic && cell.type === 'status-ok'"
            class="table-button"
          >
            OK
          </button>
          <span v-else-if="cell.type === 'claim-no'">
            <ClaimTooltip
              :ref="`claim-tooltip-${row.id}`"
              :label="cell.value"
              :variant="row.id"
            />
          </span>
          <span v-else-if="cell.type === 'resident'">
            <ResidentTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </span>
          <span v-else-if="cell.type === 'facility'">
            <FacilityTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </span>
          <span v-else>
            {{ cell }}
          </span>
        </td>
      </template>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable'
import ClaimTooltip from '@/components/tooltips/ClaimTooltip'
import ResidentTooltip from '@/components/tooltips/ResidentTooltip'
import FacilityTooltip from '@/components/tooltips/FacilityTooltip'
import claimIdVariator from '@/variators/claimIdVariator'
import dollarVariantVariator from '@/variators/dollarValueVariator'

const rowTemplate = (id, fields) => ({
  id,
  data: [
    {
      dynamic: true,
      type: 'view-edit'
    },
    {
      dynamic: true,
      type: 'status-ok'
    },
    {
      value: claimIdVariator(),
      type: 'claim-no'
    },
    ...fields,
    dollarVariantVariator(772, 2790)
  ]
})

export default {
  components: {
    BaseTable,
    ClaimTooltip,
    ResidentTooltip,
    FacilityTooltip
  },
  data() {
    return {
      rows: [
        rowTemplate(1, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Vitela, Francisco',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(2, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Gunkel, Michael',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(3, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Acosta, Porfirio',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(4, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Blanton, James',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(5, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Cannada, Dorothy',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(6, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Cave, Randy',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(7, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Ferrell, Jerry',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(8, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Green, Hattie',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(9, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Gwin, Anita',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ]),
        rowTemplate(10, [
          '11/02/2020 - 11/08/2020',
          {
            value: 'Gunkel, Michael',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Ready'
        ])
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  td {
    text-align: center;
  }

  td.align-left {
    padding-left: 12px !important;
    text-align: left;
  }
}
</style>
