<template>
  <table class="table">
    <thead class="table__header">
      <tr>
        <th colspan="4" class="table__header__th">
          Client Information
          <span class="table__header__timestamp">
            {{
              type === 'left'
                ? 'Last updated on 11/10/2020 at 08:54 AM CST'
                : 'Last updated on 11/10/2020 at 09:17 AM CST'
            }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr>
        <th class="table__body__th">Name</th>
        <th class="table__body__th">Date of Birth</th>
        <th class="table__body__th">Gender</th>
        <th class="table__body__th">Medicaid ID</th>
      </tr>
      <tr>
        <td class="table__body__td">
          {{ data.name }}
        </td>
        <td class="table__body__td">
          {{ data.dateOfBirth }}
        </td>
        <td class="table__body__td">
          {{ data.gender }}
        </td>
        <td class="table__body__td">
          {{ data.medicaidId }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      instances: {
        default: {
          name: 'Anthony Abbe',
          dateOfBirth: '09/09/1953',
          gender: 'Male',
          medicaidId: '525211015'
        },
        florina: {
          name: 'Florina Abeyta',
          dateOfBirth: '07/03/1959',
          gender: 'Female',
          medicaidId: '618856802'
        }
      }
    }
  },
  computed: {
    data() {
      return this.instances[this.$store.state.typeOfSideBySide]
    }
  }
}
</script>
