<template>
  <table class="table">
    <thead class="table__header">
      <tr>
        <th colspan="4" class="table__header__th">
          Income/Co-Payment (Applied Income)
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr>
        <th class="table__body__th">#</th>
        <th class="table__body__th">Eff. date</th>
        <th class="table__body__th">End date</th>
        <th class="table__body__th">Amount</th>
      </tr>
      <tr v-for="(item, i) in data" :key="i">
        <td class="table__body__td">
          {{ i + 1 }}
        </td>
        <td class="table__body__td">
          {{ item.effDate }}
        </td>
        <td class="table__body__td">
          {{ item.endDate }}
        </td>
        <td class="table__body__td">
          {{ item.amount }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      instances: {
        default: {
          left: [
            {
              effDate: '05/01/2020',
              endDate: '05/31/2020',
              amount: '$1035.00'
            },
            {
              effDate: '06/01/2020',
              endDate: '06/30/2020',
              amount: '$1035.00'
            },
            {
              effDate: '07/01/2020',
              endDate: '07/31/2020',
              amount: '$1035.00'
            },
            {
              effDate: '08/01/2020',
              endDate: '08/31/2020',
              amount: '$1035.00'
            },
            {
              effDate: '09/01/2020',
              endDate: '12/31/9999',
              amount: '$1036.00'
            }
          ],
          right: [
            {
              effDate: '05/01/2020',
              endDate: '05/31/2020',
              amount: '$1035.00'
            },
            {
              effDate: '06/01/2020',
              endDate: '06/30/2020',
              amount: '$1035.00'
            },
            {
              effDate: '07/01/2020',
              endDate: '07/31/2020',
              amount: '$1035.00'
            },
            {
              effDate: '08/01/2020',
              endDate: '08/31/2020',
              amount: '$1035.00'
            },
            {
              effDate: '09/01/2020',
              endDate: '12/31/9999',
              amount: '$1036.00'
            }
          ]
        },
        florina: {
          left: [
            {
              effDate: '',
              endDate: '',
              amount: ''
            },
            {
              effDate: '',
              endDate: '',
              amount: ''
            },
            {
              effDate: '',
              endDate: '',
              amount: ''
            },
            {
              effDate: '',
              endDate: '',
              amount: ''
            },
            {
              effDate: '01/01/2020',
              endDate: '12/31/9999',
              amount: '$875.00'
            }
          ],
          right: [
            {
              effDate: '01/01/2019',
              endDate: '04/30/2019',
              amount: '$860.00'
            },
            {
              effDate: '05/01/2019',
              endDate: '05/31/2019',
              amount: '$585.14'
            },
            {
              effDate: '06/01/2019',
              endDate: '06/30/2019',
              amount: '$648.00'
            },
            {
              effDate: '07/01/2019',
              endDate: '12/31/2019',
              amount: '$860.00'
            },
            {
              effDate: '01/01/2020',
              endDate: '12/31/9999',
              amount: '$875.00'
            }
          ]
        }
      }
    }
  },
  computed: {
    data() {
      return this.instances[this.$store.state.typeOfSideBySide][this.type]
    }
  }
}
</script>
