<template>
  <div id="app">
    <TheNavbar @show-sidebar="$refs.sidebar.showSidebar = true" />

    <TheSidebar ref="sidebar" />

    <ClaimPanel ref="claimPanel" />

    <ResidentNotesPanel ref="residentNotesPanel" />

    <SideBySideModal ref="sideBySide" />

    <div id="content" class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import TheNavbar from '@/components/layout/TheNavbar'
import TheSidebar from '@/components/layout/TheSidebar'
import ClaimPanel from '@/components/widgets/ClaimPanel'
import ResidentNotesPanel from '@/components/widgets/ResidentNotesPanel'
import SideBySideModal from '@/components/modals/SideBySideModal'

export default {
  components: {
    TheNavbar,
    TheSidebar,
    ClaimPanel,
    ResidentNotesPanel,
    SideBySideModal
  },
  created() {
    this.$eventHub.$on('toggle-claim-panel', () => {
      this.$refs.claimPanel.isVisible = !this.$refs.claimPanel.isVisible
    })
    this.$eventHub.$on('toggle-resident-notes-panel', () => {
      this.$refs.residentNotesPanel.isVisible = !this.$refs.residentNotesPanel
        .isVisible
    })
    this.$eventHub.$on('toggle-side-by-side', () => {
      this.$store.commit('setTypeOfSideBySide', 'default')
      this.$refs.sideBySide.isVisible = !this.$refs.sideBySide.isVisible
    })
    this.$eventHub.$on('toggle-side-by-side-florina', () => {
      this.$store.commit('setTypeOfSideBySide', 'florina')
      this.$refs.sideBySide.isVisible = !this.$refs.sideBySide.isVisible
    })
  },
  beforeDestroy() {
    this.$eventHub.$off('toggle-claim-panel')
    this.$eventHub.$off('toggle-resident-notes-panel')
    this.$eventHub.$off('toggle-side-by-side')
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main';

#app {
  min-height: 100vh;
  position: relative;
}

.content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  padding: 0 62px 28px 62px;

  &--residents {
    padding-bottom: 0;
    padding-left: 0;
  }

  &:not(.content--residents) {
    @media only screen and (max-width: 1600px) {
      padding: 0 20px 28px 20px;
    }
  }
}
</style>
