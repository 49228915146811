<template>
  <div class="quick-stats">
    <div
      class="quick-stats__title"
      :class="{ 'quick-stats__title--larger': largerBg }"
    >
      {{ title }}
    </div>
    <div class="quick-stats__content">
      <div class="quick-stats__content__label">{{ label }}</div>
      <div class="quick-stats__content__value">{{ value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    largerBg: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.quick-stats {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
  width: 348px;

  &:hover {
    transform: scale(1.05);
  }

  &__title {
    align-items: center;
    background-image: url('../../assets/images/quick-stats-bg.svg');
    color: #fff;
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 27px;
    letter-spacing: 0.1px;
    line-height: 16px;
    padding-left: 17px;

    &--larger {
      background-image: url('../../assets/images/quick-stats-bg-lg.svg');
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 20px 17px 24px 17px;

    &__label {
      color: #0d283e;
      font-size: 16px;
      letter-spacing: 0.1px;
      line-height: 16px;
      margin-right: 20px;
    }

    &__value {
      background: #dfe1e6;
      border-radius: 99px;
      color: #081f32;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.018em;
      line-height: 143%;
      padding: 0 10px;
    }
  }
}
</style>
