<template>
  <div class="app-checkbox" @click="onChange">
    <div
      class="app-checkbox__icon"
      :class="{
        [`app-checkbox__icon--${variant}`]: true,
        'app-checkbox__icon--active': isChecked
      }"
    >
      <Transition name="fade">
        <svg
          v-if="isChecked"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
          />
        </svg>
      </Transition>
    </div>
    <div class="app-checkbox__label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    label: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      isChecked: this.value !== undefined ? this.value : this.checked
    }
  },
  watch: {
    value(value) {
      this.isChecked = value
    },
    checked(value) {
      this.isChecked = value
    }
  },
  methods: {
    onChange() {
      this.isChecked = !this.isChecked
      this.$emit('input', this.isChecked)
      this.$emit('change', this.isChecked)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-checkbox {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;

  &__icon {
    $this: #{&};

    height: 18px;
    margin-right: 6px;
    transition: all 0.2s ease;
    width: 18px;

    svg {
      height: 100%;
      width: 100%;
    }

    // When unchecked.
    svg path:last-of-type {
      fill: #717171;
    }

    // Variants.
    &--indigo#{$this}--default svg path:last-of-type {
      fill: #000;
    }

    &--indigo#{$this}--active svg path:last-of-type {
      fill: #0d283e;
    }
  }

  &:active &__icon {
    transform: scale3d(0.88, 0.88, 1);
  }
}
</style>
