<template>
  <div>
    <div class="patient-report-table">
      <ClientInformationTable />
      <ClientInformationTable type="right" />

      <ServiceAuthTable />
      <ServiceAuthTable type="right" />

      <MedicaidEligibilityTable />
      <MedicaidEligibilityTable type="right" />

      <LevelsOfServiceTable />
      <LevelsOfServiceTable type="right" />

      <AppliedIncomeTable />
      <AppliedIncomeTable type="right" />

      <ManagedCareTable />
      <ManagedCareTable type="right" />
    </div>
  </div>
</template>

<script>
import ClientInformationTable from '@/components/tables/ClientInformationTable'
import ServiceAuthTable from '@/components/tables/ServiceAuthTable'
import MedicaidEligibilityTable from '@/components/tables/MedicaidEligibilityTable'
import LevelsOfServiceTable from '@/components/tables/LevelsOfServiceTable'
import AppliedIncomeTable from '@/components/tables/AppliedIncomeTable'
import ManagedCareTable from '@/components/tables/ManagedCareTable'

export default {
  components: {
    ClientInformationTable,
    ServiceAuthTable,
    MedicaidEligibilityTable,
    LevelsOfServiceTable,
    AppliedIncomeTable,
    ManagedCareTable
  }
}
</script>

<style lang="scss" scoped>
.patient-report-table {
  color: #333;
  column-gap: 42px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
  padding: 0 128px;
}

::v-deep .table {
  border-collapse: collapse;
  width: 100%;

  &--hidden {
    visibility: hidden;
  }

  &--none {
    display: none;
  }

  &__header__th,
  &__header__td,
  &__body__th,
  &__body__td {
    border-bottom: 1px solid #dadada;
    padding-left: 12px;
    text-align: left;
  }

  &__header {
    background: #f5f5f5;
    position: sticky;
    top: 122px;

    &__th {
      font-weight: 600;
      height: 56px;
      position: relative;
    }

    &__timestamp {
      font-size: 11px;
      font-style: italic;
      font-weight: normal;
      position: absolute;
      right: 10px;
      top: 8px;
    }
  }

  &__body {
    &__th {
      font-size: 14px;
      font-weight: 600;
      height: 40px;
    }

    &__td {
      height: 56px;
    }

    &__td--missing-data {
      border: none;
      text-align: center;

      &__icon {
        vertical-align: middle;
      }
    }

    &__tr--mismatch,
    &__td--mismatch {
      background: rgba(232, 0, 0, 0.075);
      border-bottom: 2px solid #e80000;
    }

    &__no-data {
      padding-top: 10px;
      text-align: center;
      width: 100%;
    }
  }
}
</style>
