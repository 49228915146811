import { render, staticRenderFns } from "./ArAgingTable.vue?vue&type=template&id=6bdde318&scoped=true&"
import script from "./ArAgingTable.vue?vue&type=script&lang=js&"
export * from "./ArAgingTable.vue?vue&type=script&lang=js&"
import style0 from "./ArAgingTable.vue?vue&type=style&index=0&id=6bdde318&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bdde318",
  null
  
)

export default component.exports