<template>
  <table class="table">
    <thead class="table__header">
      <tr>
        <th colspan="4" class="table__header__th">
          Service Authorization Information
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr>
        <th class="table__body__th">#</th>
        <th class="table__body__th">Eff. date</th>
        <th class="table__body__th">End date</th>
        <th class="table__body__th">Svc code</th>
      </tr>
      <tr v-for="(item, i) in data" :key="i">
        <td class="table__body__td">
          {{ i + 1 }}
        </td>
        <td class="table__body__td">
          {{ item.effDate }}
        </td>
        <td class="table__body__td">
          {{ item.endDate }}
        </td>
        <td class="table__body__td">
          {{ item.serviceCode }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      instances: {
        default: {
          left: [
            {
              effDate: '05/17/2020',
              endDate: '08/19/2020',
              serviceCode: '1: DC'
            },
            {
              effDate: '08/25/2020',
              endDate: '12/31/9999',
              serviceCode: '1: DC'
            }
          ],
          right: [
            {
              effDate: '05/17/2020',
              endDate: '08/19/2020',
              serviceCode: '1: DC'
            },
            {
              effDate: '08/25/2020',
              endDate: '12/31/9999',
              serviceCode: '1: DC'
            }
          ]
        },
        florina: {
          left: [
            {
              effDate: '02/01/2020',
              endDate: '08/20/2020',
              serviceCode: '1: DC'
            },
            {
              effDate: '02/01/2020',
              endDate: '07/23/2020',
              serviceCode: '7A: OT - NF Spec Srvcs'
            },
            {
              effDate: '02/01/2020',
              endDate: '07/24/2020',
              serviceCode: '8A: OT - NF Spec Srvcs'
            },
            {
              effDate: '07/24/2020',
              endDate: '08/20/2020',
              serviceCode: '7A: OT - NF Spec Srvcs'
            },
            {
              effDate: '07/25/2020',
              endDate: '08/20/2020',
              serviceCode: '8A: OT - NF Spec Srvcs'
            },
            {
              effDate: '10/15/2020',
              endDate: '12/31/9999',
              serviceCode: '1: DC'
            },
            {
              effDate: '10/16/2020',
              endDate: '10/16/2020',
              serviceCode: '8AE: PT-NF - Asmt Spec Srvcs'
            },
            {
              effDate: '10/19/2020',
              endDate: '10/19/2020',
              serviceCode: '7AE: PT-NF - Asmt Spec Srvcs'
            },
            {
              effDate: '11/04/2020',
              endDate: '05/02/2020',
              serviceCode: '7A: OT - NF Spec Srvcs'
            },
            {
              effDate: '11/04/2020',
              endDate: '05/02/2020',
              serviceCode: '8A: OT - NF Spec Srvcs'
            }
          ],
          right: [
            {
              effDate: '02/01/2020',
              endDate: '08/20/2020',
              serviceCode: '1: DC'
            },
            {
              effDate: '',
              endDate: '',
              serviceCode: ''
            },
            {
              effDate: '',
              endDate: '',
              serviceCode: ''
            },
            {
              effDate: '',
              endDate: '',
              serviceCode: ''
            },
            {
              effDate: '',
              endDate: '',
              serviceCode: ''
            },
            {
              effDate: '10/15/2020',
              endDate: '12/31/9999',
              serviceCode: '1: DC'
            },
            {
              effDate: '',
              endDate: '',
              serviceCode: ''
            },
            {
              effDate: '',
              endDate: '',
              serviceCode: ''
            },
            {
              effDate: '',
              endDate: '',
              serviceCode: ''
            },
            {
              effDate: '',
              endDate: '',
              serviceCode: ''
            }
          ]
        }
      }
    }
  },
  computed: {
    data() {
      return this.instances[this.$store.state.typeOfSideBySide][this.type]
    }
  }
}
</script>
