<template>
  <BaseWidget>
    <div class="widget__title">
      <span>Claims</span>
      <div class="widget__dropdown">
        Week
        <img src="@/assets/images/caret-down.svg" />
      </div>
    </div>

    <p class="widget__value">
      $3,850,057
      <span class="widget__percentage--up">
        <img src="@/assets/images/caret-up.svg" />
        10%
      </span>
    </p>
    <span class="widget__subtext">
      Compared to $3,500,051 last week
    </span>
    <p class="widget__value">
      3,513
      <span class="widget__percentage--up">
        <img src="@/assets/images/caret-up.svg" />
        10%
      </span>
    </p>
    <span class="widget__subtext">
      Compared to 3,193 last week
    </span>
    <p class="widget__value">
      $1,109
      <span class="widget__percentage--up">
        <img src="@/assets/images/caret-up.svg" />
        10%
      </span>
    </p>
    <span class="widget__subtext">
      Average claims value
    </span>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'

export default {
  components: { BaseWidget }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px 30px;

  &__title {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
  }

  &__dropdown {
    margin-left: auto;
    width: 110px;
  }

  &__value {
    font-size: 34px;
    font-weight: 400;
    letter-spacing: -0.44px;
    line-height: 44px;
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
</style>
