<template>
  <BaseWidget>
    <p class="widget__title">AR Aging</p>
    <div class="ar-aging-items">
      <div class="ar-aging-items__item">
        <div class="ar-aging-items__item__days">0-30 days</div>
        <div class="ar-aging-items__item__value">$11,550,308</div>
        <div class="ar-aging-items__item__progress">
          <BaseRadialProgressBar
            class="ar-aging-items__item__progress__radial-progress"
            :progress="71"
            color="#302769"
          >
            <span class="ar-aging-items__item__progress__percentage">
              <span>71</span>
              <sup>%</sup>
            </span>
          </BaseRadialProgressBar>
          <div>
            <div class="ar-aging-items__item__progress__label">
              Total claims
            </div>
            <div class="ar-aging-items__item__progress__value">10,503</div>
          </div>
        </div>
      </div>

      <div class="ar-aging-items__item-divider" />

      <div class="ar-aging-items__item">
        <div class="ar-aging-items__item__days">31-60 days</div>
        <div class="ar-aging-items__item__value">$2,618,072</div>
        <div class="ar-aging-items__item__progress">
          <BaseRadialProgressBar
            class="ar-aging-items__item__progress__radial-progress"
            :progress="16"
            color="#302769"
          >
            <span class="ar-aging-items__item__progress__percentage">
              <span>16</span>
              <sup>%</sup>
            </span>
          </BaseRadialProgressBar>
          <div>
            <div class="ar-aging-items__item__progress__label">
              Total claims
            </div>
            <div class="ar-aging-items__item__progress__value">2,380</div>
          </div>
        </div>
      </div>

      <div class="ar-aging-items__item-divider" />

      <div class="ar-aging-items__item">
        <div class="ar-aging-items__item__days">61-90 days</div>
        <div class="ar-aging-items__item__value">$1,386,021</div>
        <div class="ar-aging-items__item__progress">
          <BaseRadialProgressBar
            class="ar-aging-items__item__progress__radial-progress"
            :progress="9"
            color="#302769"
          >
            <span class="ar-aging-items__item__progress__percentage">
              <span>9</span>
              <sup>%</sup>
            </span>
          </BaseRadialProgressBar>
          <div>
            <div class="ar-aging-items__item__progress__label">
              Total claims
            </div>
            <div class="ar-aging-items__item__progress__value">1,261</div>
          </div>
        </div>
      </div>

      <div class="ar-aging-items__item-divider" />

      <div class="ar-aging-items__item">
        <div class="ar-aging-items__item__days">91+ days</div>
        <div class="ar-aging-items__item__value">$500,503</div>
        <div class="ar-aging-items__item__progress">
          <BaseRadialProgressBar
            class="ar-aging-items__item__progress__radial-progress"
            :progress="4"
            color="#302769"
          >
            <span class="ar-aging-items__item__progress__percentage">
              <span>4</span>
              <sup>%</sup>
            </span>
          </BaseRadialProgressBar>
          <div>
            <div class="ar-aging-items__item__progress__label">
              Total claims
            </div>
            <div class="ar-aging-items__item__progress__value">455</div>
          </div>
        </div>
      </div>
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'
import BaseRadialProgressBar from '@/components/helpers/BaseRadialProgressBar'

export default {
  components: { BaseWidget, BaseRadialProgressBar }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 24px 30px;
}

.ar-aging-items {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;

  &__item-divider {
    align-self: stretch;
    background-color: #dee2f7;
    display: flex;
    margin: 0 1px;
    width: 1px;
  }

  &__item {
    @media only screen and (max-width: 1600px) {
      padding-left: 8px;
      padding-right: 8px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    &__days {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.44px;
      line-height: 24px;

      @media only screen and (max-width: 1600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &__value {
      font-size: 30px;
      letter-spacing: -0.44px;
      line-height: 44px;

      @media only screen and (max-width: 1600px) {
        font-size: 26px;
      }
    }

    &__progress {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-top: 12px;

      &__radial-progress {
        margin-right: 15px;

        @media only screen and (max-width: 1600px) {
          margin-right: 10px;
        }
      }

      &__percentage {
        font-size: 24px;
        font-weight: 600;
        line-height: 48px;

        sup {
          font-size: 12px;
          letter-spacing: 0.343782px;
          line-height: 25px;
          top: -0.75em;
        }
      }

      &__label {
        font-size: 16px;
        font-weight: 200;
        letter-spacing: 0.44px;
        line-height: 24px;

        @media only screen and (max-width: 1600px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__value {
        font-size: 22px;
        line-height: 36px;
      }
    }
  }
}
</style>
