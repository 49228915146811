<template>
  <div class="modal" v-if="isVisible">
    <SideBySideModalHeader @close="isVisible = false" />
    <SideBySideModalTabs />
    <SideBySideModalTable />
  </div>
</template>

<script>
import SideBySideModalHeader from '@/components/modals/SideBySideModalHeader'
import SideBySideModalTabs from '@/components/modals/SideBySideModalTabs'
import SideBySideModalTable from '@/components/modals/SideBySideModalTable'

export default {
  components: {
    SideBySideModalHeader,
    SideBySideModalTabs,
    SideBySideModalTable
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    closeModal() {
      alert('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  background: #fff;
  min-height: 100vh;
  position: absolute;
  top: 49px;
  width: 100%;
  z-index: 6;
}
</style>
