<template>
  <div class="wrapper" :class="{ 'wrapper--grid': grid }">
    <table>
      <thead>
        <tr>
          <td v-if="selectable" style="text-align: center">
            <div
              v-if="rows.length === selectedRows.length"
              class="select-all"
              @click="cancelSelection"
            >
              Deselect all
            </div>
            <div v-else class="select-all" @click="selectAll">Select all</div>
          </td>
          <slot name="header" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in rows"
          :key="row.id"
          :class="{ 'rich-hover': richHover }"
        >
          <td v-if="selectable">
            <TableCheckbox
              :checked="isSelected(row.id)"
              @toggle="toggleSelectedRow(row.id)"
            />
          </td>
          <slot name="row" :row="row" :show-side-by-side="showSideBySide" />
          <div v-if="richHover" class="rich-hover__wrapper">
            <div class="rich-hover__box">
              <div
                @click="
                  $eventHub.$emit(
                    isFlorina(row)
                      ? 'toggle-side-by-side-florina'
                      : 'toggle-side-by-side'
                  )
                "
                class="rich-hover__icon"
              >
                <img
                  class="rich-hover__icon__image"
                  src="@/assets/images/open-side-by-side.svg"
                />
              </div>
              <div
                @click="$eventHub.$emit('toggle-resident-notes-panel')"
                class="rich-hover__icon"
              >
                <img
                  class="rich-hover__icon__image"
                  src="@/assets/images/toolbar-notes.svg"
                />
              </div>
              <div class="rich-hover__icon">
                <img
                  class="rich-hover__icon__image"
                  src="@/assets/images/toolbar-saved.svg"
                />
              </div>
              <div class="rich-hover__icon">
                <img
                  class="rich-hover__icon__image"
                  src="@/assets/images/toolbar-kabob.svg"
                />
              </div>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
    <Transition name="fade">
      <div
        v-if="submitable && totalSelected && totalSelected.total > 0"
        class="table-submit-container"
      >
        <div class="table-submit-container__text">
          Ready to submit
          <strong>{{ totalSelected.total }}</strong>
          {{ parseInt(totalSelected.total) > 1 ? 'claims' : 'claim' }} ({{
            totalSelected.value | currency
          }})
        </div>
        <div class="table-submit-container__button">
          <span v-if="submitButtonText">{{ submitButtonText }}</span>
          <span v-else>Submit</span>
        </div>
        <div class="table-submit-container__cancel" @click="cancelSelection">
          Cancel
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import TableCheckbox from '@/components/helpers/TableCheckbox'

export default {
  components: {
    TableCheckbox
  },
  props: {
    submitable: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    },
    accumulatorIndex: {
      type: Number,
      default: 0
    },
    rows: {
      type: Array,
      required: true
    },
    grid: {
      type: Boolean,
      default: false
    },
    submitButtonText: {
      type: String,
      default: ''
    },
    richHover: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedRows: []
    }
  },
  computed: {
    totalSelected() {
      if (!(this.selectable && this.submitable)) {
        return null
      }

      return {
        total: this.selectedRows.length,
        value: this.rows
          .filter(row => this.selectedRows.includes(row.id))
          .map(row =>
            parseFloat(
              row.data[this.accumulatorIndex].replace('$', '').replace(',', '')
            )
          )
          .reduce((sum, curent) => sum + curent, 0)
      }
    }
  },
  methods: {
    isFlorina(row) {
      return row.data.find(item => {
        return (
          item.value &&
          typeof item.value === 'string' &&
          item.value.includes('Florina')
        )
      })
    },
    selectAll() {
      this.selectedRows = this.rows.map(row => row.id)
    },
    toggleSelectedRow(id) {
      if (!this.selectedRows.includes(id)) {
        this.selectedRows.push(id)
      } else {
        this.selectedRows = this.selectedRows.filter(rowId => rowId !== id)
      }
    },
    isSelected(id) {
      return this.selectedRows.includes(id)
    },
    cancelSelection() {
      this.selectedRows = []
    },
    showSideBySide() {
      // if (this.$route.path.includes('ar-aging')) return

      this.$eventHub.$emit('toggle-side-by-side')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(51, 51, 51, 0.3);

  table {
    border-collapse: collapse;
    width: 100%;

    thead {
      td {
        color: #333;
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
        padding: 16px 0;
      }

      td:first-of-type {
        padding-left: 28px;
      }

      td:last-of-type {
        padding-right: 36px;
      }

      .select-all {
        color: #333;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        line-height: 21px;
        text-decoration-line: underline;
        transform: translateX(-5px);
        width: 80px;
      }
    }

    tbody {
      tr {
        transition: 0.1s ease;

        /* stylelint-disable-next-line no-descending-specificity */
        td {
          color: #1d1c1d;
          font-size: 14px;
          letter-spacing: 0.228571px;
          line-height: 20px;
          padding: 18px 0;
          position: relative;

          &::after {
            background-color: #adb1b8;
            bottom: -1px;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            transition: 0.2s ease;
            width: 100%;
            z-index: 1;
          }
        }

        td:first-of-type {
          padding-left: 28px;

          &::after {
            left: 28px;
            width: calc(100% - 28px);
          }
        }

        td:last-of-type {
          padding-right: 36px;

          &::after {
            width: calc(100% - 36px);
          }
        }

        &:last-of-type {
          td::after {
            display: none;
          }

          td:first-of-type {
            border-bottom-left-radius: 10px;
          }

          td:last-of-type {
            border-bottom-right-radius: 10px;
          }
        }

        &.rich-hover {
          .rich-hover__wrapper {
            position: relative;
          }

          .rich-hover__box {
            background: #fff;
            border: 0.5px solid #adb1b8;
            border-radius: 4px;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.102956);
            box-sizing: border-box;
            display: flex;
            opacity: 0;
            padding: 5px;
            position: absolute;
            right: -19px;
            top: 0;
            transform: translateY(-29px);
            transition: 0.1s ease-in-out;
            visibility: hidden;
            z-index: -1;

            /* stylelint-disable-next-line max-nesting-depth */
            @media only screen and (max-width: 1600px) {
              right: -10px;
              transform: translateY(-25px);
            }
          }

          .rich-hover__icon {
            align-items: center;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            height: 30px;
            justify-content: center;
            margin-right: 5px;
            transition: 0.2s ease;
            width: 30px;
          }

          .rich-hover__icon:hover {
            background-color: #eef0f3;
            border-radius: 50%;
          }

          .rich-hover__icon__image {
            max-height: 28px;
            max-width: 28px;
          }

          .rich-hover__icon:last-of-type {
            margin-right: 0;
          }
        }

        &:hover {
          background: #f7f8fa;
        }

        &:hover.rich-hover {
          .rich-hover__box {
            opacity: 1;
            visibility: visible;
            z-index: 2;
          }
        }
      }
    }
  }

  &--grid {
    /* stylelint-disable-next-line no-descending-specificity */
    tbody {
      /* stylelint-disable-next-line no-descending-specificity */
      tr td {
        border-right: solid 1px #adb1b8;
        min-width: 100px;

        /* stylelint-disable-next-line no-descending-specificity */
        &:not(:first-of-type) {
          text-align: center;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        &:first-of-type {
          padding-left: 28px !important;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        &:last-of-type {
          border-right: 0;
          padding-right: 36px !important;
        }
      }
    }
  }
}

.table-submit-container {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  height: 90px;
  left: 0;
  padding: 35px 70px;
  padding-right: 39px;
  position: fixed;
  width: 100%;
  z-index: 1;

  &__text {
    color: #1d1c1d;
    font-size: 20px;
    letter-spacing: 0.228571px;
    line-height: 20px;
    margin-left: auto;
    margin-right: 52px;
  }

  &__button {
    background: #622aeb;
    border-radius: 4px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.102956);
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    height: 42px;
    line-height: 16px;
    margin-right: 10px;
    padding: 12.6px 31px;
    text-align: center;
  }

  &__cancel {
    color: #1d1c1d;
    cursor: pointer;
    font-size: 18px;
    height: 42px;
    letter-spacing: 0.228571px;
    line-height: 20px;
    padding: 12.6px 31px;
  }
}

.table-button {
  align-items: center;
  background: #fff;
  border: 1px solid #1d1c1d;
  border-radius: 99px;
  color: #1d1c1d;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-feature-settings: 'ss01' on;
  font-size: 12px;
  justify-content: center;
  letter-spacing: 0.033em;
  line-height: 126%;
  min-width: 52px;
  padding: 2px 8px;
  position: relative;

  &--offset-left {
    margin-left: 30px !important;
  }

  &--hover-primary {
    &:hover {
      background: #081f32;
      color: #fff;
    }
  }
}

.table-error-button {
  align-items: center;
  background: #fff;
  border: 1px solid #e71a0b;
  border-radius: 99px;
  color: #e71a0b;
  cursor: pointer;
  display: inline-flex;
  font-feature-settings: 'ss01' on;
  font-size: 12px;
  letter-spacing: 0.033em;
  line-height: 126%;
  padding: 2px 8px;
  position: relative;
  width: 78px;

  &:hover,
  &--active {
    background: #e71a0b;
    color: #fff;
  }

  &::after {
    background-image: url('../../assets/images/error-arrow.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 5px;
    position: absolute;
    right: 8.25px;
    top: calc(50% - 1.8px);
    width: 8.75px;
  }

  &:hover::after {
    background-image: url('../../assets/images/error-arrow-hover.svg');
  }
}

.table-alerts-button {
  align-items: center;
  background: #fff;
  border: 1px solid #5900d0;
  border-radius: 99px;
  color: #5900d0;
  cursor: pointer;
  display: flex;
  font-feature-settings: 'ss01' on;
  font-size: 12px;
  letter-spacing: 0.033em;
  line-height: 126%;
  padding: 2px 8px;
  position: relative;
  width: 78px;

  &:hover,
  &--active {
    background: #5900d0;
    color: #fff;
  }

  &::after {
    background-image: url('../../assets/images/alerts-arrow.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 5px;
    position: absolute;
    right: 8.25px;
    top: calc(50% - 1.8px);
    width: 8.75px;
  }

  &:hover::after {
    background-image: url('../../assets/images/alerts-arrow-hover.svg');
  }
}

.table-view-button {
  background: #fff;
  border: 1px solid #adb1b8;
  border-radius: 4px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.102956);
  box-sizing: border-box;
  color: #585c64;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  padding: 7px 12px;
  text-align: center;
  text-transform: capitalize;
}
</style>
