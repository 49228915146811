<template>
  <div :class="['panel', { 'panel--visible': isVisible }]">
    <div class="panel__header">
      <div class="panel__header__title">
        Resident notes
      </div>

      <div class="panel__header__options">
        Show:
        <span class="panel__header__label--active">Current</span>
        <span>Archived</span>
        <div class="panel__header__divider"></div>
        <div class="panel__header__close-button" @click="isVisible = false">
          <img src="@/assets/images/close-drawer.svg" />
        </div>
      </div>
    </div>

    <div class="panel__sub-header">
      Yesterday
    </div>

    <div class="panel__note">
      <div>
        <div class="panel__initials">
          DA
        </div>
      </div>
      <div>
        <div class="panel__note__header">
          <span class="panel__note__title">
            <img src="@/assets/images/bookmark-filled.svg" />
            Saved patient note
          </span>
          <span class="panel__note__date">
            Jul 23th at 7:39 PM
          </span>
        </div>

        <span class="panel__note__text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </span>
      </div>
    </div>

    <div class="panel__note">
      <div>
        <div class="panel__initials">
          DA
        </div>
      </div>
      <div class="panel__note__header">
        <span class="panel__note__title">
          <img src="@/assets/images/bookmark-filled.svg" />
          Saved MESAV
        </span>
        <span class="panel__note__date">
          Jul 23th at 7:39 PM
        </span>
      </div>
    </div>

    <div class="panel__note">
      <div>
        <div class="panel__initials">
          DA
        </div>
      </div>
      <div>
        <div class="panel__note__header">
          <span class="panel__note__title">
            <img src="@/assets/images/bookmark-filled.svg" />
            Saved patient note
          </span>
          <span class="panel__note__date">
            Jul 23th at 4:21 AM
          </span>
        </div>

        <span class="panel__note__text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </span>
      </div>
    </div>

    <div class="panel__sub-header">
      Last week
    </div>

    <div class="panel__note">
      <div>
        <div class="panel__initials">
          DA
        </div>
      </div>
      <div>
        <div class="panel__note__header">
          <span class="panel__note__title">
            <img src="@/assets/images/bookmark-filled.svg" />
            Saved patient note
          </span>
          <span class="panel__note__date">
            Jul 11th at 7:14 PM
          </span>
        </div>

        <span class="panel__note__text">
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
          nisi
        </span>
      </div>
    </div>

    <div class="panel__note">
      <div>
        <div class="panel__initials">
          DA
        </div>
      </div>
      <div>
        <div class="panel__note__header">
          <span class="panel__note__title">
            <img src="@/assets/images/bookmark-filled.svg" />
            Saved patient note
          </span>
          <span class="panel__note__date">
            Jul 5th at 3:39 PM
          </span>
        </div>

        <span class="panel__note__text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </span>
      </div>
    </div>

    <div class="panel__textarea">
      <div>
        <div class="panel__initials">
          DA
        </div>
      </div>
      <div class="panel__textarea__col">
        <textarea
          class="panel__textarea__input"
          cols="30"
          rows="10"
          placeholder="Add note..."
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: #1d1c1d;
  font-size: 14px;
  height: 100vh;
  letter-spacing: 0.218182px;
  line-height: 24px;
  overflow-y: scroll;
  padding-bottom: 48px;
  position: fixed;
  right: -600px;
  top: 49px;
  transition: right 0.3s linear;
  width: 600px;
  z-index: 20;

  &--visible {
    right: 0;
  }

  &__sub-header {
    border-top: 1px solid #b7bac2;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 20px;
  }

  &__textarea {
    display: flex;
    padding: 20px;

    &__col {
      flex-grow: 1;
      padding-left: 22px;
    }

    &__input {
      background: #fcfeff;
      border: 1px solid #b9d2de;
      border-radius: 6px;
      padding: 12px;
      width: 100%;
    }
  }

  &__initials {
    align-items: center;
    background: #0d283e;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 25px;
    justify-content: center;
    line-height: 15px;
    width: 25px;
  }

  &__note {
    border-top: 1px solid #b7bac2;
    color: #1d1c1d;
    display: flex;
    font-size: 15px;
    gap: 20px;
    line-height: 21px;
    padding: 20px;

    &__text {
      display: block;
      margin-top: 13px;
    }

    &__header {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      margin-top: 6px;
      width: 100%;
    }

    &__title {
      color: #52575c;
      font-size: 13px;
      line-height: 14px;
    }

    &__date {
      color: #616061;
      font-size: 11px;
      font-style: italic;
      line-height: 11px;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    &__label {
      &--active {
        align-items: center;
        background: #3e4d64;
        border-radius: 4px;
        color: #fff;
        display: flex;
        height: 30px;
        justify-content: center;
        width: 74px;
      }
    }

    &__title {
      color: #1d1c1d;
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
    }

    &__options {
      align-items: center;
      display: flex;
      gap: 14px;
    }

    &__divider {
      background: #ccc;
      height: 30px;
      margin: 0 14px;
      width: 1px;
    }

    &__close-button {
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 36px;
      justify-content: center;
      width: 36px;

      &:hover {
        background: #eef0f3;
      }
    }
  }
}
</style>
