import Vue from 'vue'
import Vuex from 'vuex'

import VueCurrencyFilter from 'vue-currency-filter'
import vClickOutside from 'v-click-outside'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.prototype.$eventHub = new Vue()

Vue.use(Vuex)

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
  avoidEmptyDecimals: undefined
})

Vue.directive('click-outside', vClickOutside.directive)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
