<template>
  <BaseTable :rows="rows" :selectable="false">
    <template slot="header">
      <template>
        <td>Medicaid Status</td>
        <td>Resident</td>
        <td>Facility Name</td>
        <td>Payor</td>
        <td>Date Eligible</td>
        <td>Renewal Date</td>
        <td>Coverage Loss Date</td>
      </template>
    </template>
    <template slot="row" slot-scope="{ row }">
      <template>
        <td v-for="(cell, j) in row.data" :key="j">
          <span v-if="cell.type === 'resident'">
            <ResidentTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </span>
          <span v-else-if="cell.type === 'facility'">
            <FacilityTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </span>
          <span v-else>
            {{ cell }}
          </span>
        </td>
      </template>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable'
import ResidentTooltip from '@/components/tooltips/ResidentTooltip'
import FacilityTooltip from '@/components/tooltips/FacilityTooltip'

const rowTemplate = (id, fields) => ({
  id,
  data: [...fields]
})

export default {
  components: {
    BaseTable,
    ResidentTooltip,
    FacilityTooltip
  },
  data() {
    return {
      rows: [
        rowTemplate(1, [
          '607967492',
          {
            value: 'Arnold Lay',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Amerigroup',
          '10/31/2020',
          '12/31/9999',
          '-'
        ]),
        rowTemplate(2, [
          '921964532',
          {
            value: 'Ashley Wheat',
            type: 'resident'
          },
          {
            value: 'Lakeside Manor',
            type: 'facility'
          },
          'TMHP',
          '10/31/2020',
          '12/31/9999',
          '-'
        ]),
        rowTemplate(3, [
          '153967495',
          {
            value: 'Mildred Gomez',
            type: 'resident'
          },
          {
            value: 'St. Barnabas Home',
            type: 'facility'
          },
          'TMHP',
          '11/1/2020',
          '4/30/2021',
          '-'
        ]),
        rowTemplate(4, [
          '607967492',
          {
            value: 'Reta Hansen',
            type: 'resident'
          },
          {
            value: 'Long Term Care Center of America',
            type: 'facility'
          },
          'TMHP',
          '11/1/2020',
          '4/30/2021',
          '-'
        ]),
        rowTemplate(5, [
          '153967495',
          {
            value: 'James Johnson',
            type: 'resident'
          },
          {
            value: 'Long Term Care Center of America',
            type: 'facility'
          },
          'Superior',
          '11/2/2020',
          '11/1/2021',
          '-'
        ]),
        rowTemplate(6, [
          '153967495',
          {
            value: 'Francisco Vitela',
            type: 'resident'
          },
          {
            value: 'Mountain Peace North',
            type: 'facility'
          },
          'TMHP',
          '11/4/2020',
          '11/3/2021',
          '-'
        ]),
        rowTemplate(7, [
          '153967495',
          {
            value: 'Richard Wells',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '12/31/9999',
          '-'
        ]),
        rowTemplate(8, [
          '153967495',
          {
            value: 'Ronald Valentine',
            type: 'resident'
          },
          {
            value: 'St. Barnabas Home',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '12/31/9999',
          '-'
        ]),
        rowTemplate(9, [
          '153967495',
          {
            value: 'Patty Fitzgerald',
            type: 'resident'
          },
          {
            value: 'Lakeside Manor',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '11/4/2021',
          '-'
        ]),
        rowTemplate(10, [
          '153967495',
          {
            value: 'David Eells',
            type: 'resident'
          },
          {
            value: 'Lakeside Manor',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '5/6/2021',
          '-'
        ]),
        rowTemplate(11, [
          '607967492',
          {
            value: 'Brenda Rigsby',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '5/6/2021',
          '-'
        ]),
        rowTemplate(12, [
          '921964532',
          {
            value: 'Florence Whittingham',
            type: 'resident'
          },
          {
            value: 'Lakeside Manor',
            type: 'facility'
          },
          'TMHP',
          '11/7/2020',
          '5/6/2021',
          '-'
        ]),
        rowTemplate(13, [
          '153967495',
          {
            value: 'Corey Stoneking',
            type: 'resident'
          },
          {
            value: 'St. Barnabas Home',
            type: 'facility'
          },
          'TMHP',
          '11/1/2020',
          '4/30/2021',
          '-'
        ]),
        rowTemplate(14, [
          '607967492',
          {
            value: 'Hilda Cornish',
            type: 'resident'
          },
          {
            value: 'Long Term Care Center of America',
            type: 'facility'
          },
          'TMHP',
          '11/1/2020',
          '4/30/2021',
          '-'
        ]),
        rowTemplate(15, [
          '153967495',
          {
            value: 'Angela Alvarenga',
            type: 'resident'
          },
          {
            value: 'Long Term Care Center of America',
            type: 'facility'
          },
          'Superior',
          '11/2/2020',
          '11/1/2021',
          '-'
        ]),
        rowTemplate(16, [
          '153967495',
          {
            value: 'Vanessa Perez',
            type: 'resident'
          },
          {
            value: 'Mountain Peace North',
            type: 'facility'
          },
          'TMHP',
          '11/4/2020',
          '11/3/2021',
          '-'
        ]),
        rowTemplate(17, [
          '153967495',
          {
            value: 'William Kiser',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '12/31/9999',
          '-'
        ]),
        rowTemplate(18, [
          '153967495',
          {
            value: 'Angela Greene',
            type: 'resident'
          },
          {
            value: 'St. Barnabas Home',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '12/31/9999',
          '-'
        ]),
        rowTemplate(19, [
          '153967495',
          {
            value: 'Kenneth Roos',
            type: 'resident'
          },
          {
            value: 'Lakeside Manor',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '11/4/2021',
          '-'
        ]),
        rowTemplate(20, [
          '153967495',
          {
            value: 'Jacqueline Hennen',
            type: 'resident'
          },
          {
            value: 'Lakeside Manor',
            type: 'facility'
          },
          'TMHP',
          '11/5/2020',
          '5/6/2021',
          '-'
        ])
      ]
    }
  }
}
</script>
