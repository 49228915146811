<template>
  <div>
    <div
      class="tooltip-label"
      @mouseenter="isVisible = true"
      @mouseleave="isVisible = false"
    >
      <span v-if="!isVisible">{{ cell.value }}</span>
      <div class="tooltip" v-if="isVisible">
        {{ cell.value }}

        <button class="tooltip__button" @click="$emit('show-claims')">
          View
          <strong>10</strong>
          claims
          <img src="@/assets/images/caret-right.svg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cell: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isVisible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-label {
  position: relative;
}

.tooltip {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: 500;
  left: 0;
  padding: 8px 0;
  position: absolute;
  text-align: center;
  top: -20px;
  width: 150px;
  z-index: 10;

  &__button {
    background: #fff;
    border: 1px solid #adb1b8;
    border-radius: 4px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.102956);
    cursor: pointer;
    font-size: 14px;
    height: 26px;
    margin-top: 11px;
    width: 130px;
  }
}
</style>
