<template>
  <div>
    <BenefitsEligibilityBar />
    <TableSwitcher v-model="activeTable" :tabs="tabs" />
    <Transition name="fade" mode="out-in">
      <Component v-if="activeTableComponent" :is="activeTableComponent" />
    </Transition>
  </div>
</template>

<script>
import BenefitsEligibilityBar from '@/components/widgets/BenefitsEligibilityBar'
import TableSwitcher from '@/components/widgets/TableSwitcher'
import PendingTable from '@/components/tables/PendingTable'
import RecentlyEligibleTable from '@/components/tables/RecentlyEligibleTable'
import UpcomingRenewalsTable from '@/components/tables/UpcomingRenewalsTable'

export default {
  components: {
    BenefitsEligibilityBar,
    TableSwitcher
  },
  data() {
    return {
      activeTable: 'pending',
      tabs: [
        {
          id: 'all-eligible',
          label: 'All Eligible',
          disabled: true
        },
        {
          id: 'pending',
          label: 'Pending'
        },
        {
          id: 'recently-eligible',
          label: 'Recently Eligible'
        },
        {
          id: 'upcoming-renewals',
          label: 'Upcoming Renewals'
        },
        {
          id: 'lapse-benefits',
          label: 'Lapsed Benefits',
          disabled: true
        }
      ]
    }
  },
  computed: {
    activeTableComponent() {
      switch (this.activeTable) {
        case 'pending':
          return PendingTable
        case 'recently-eligible':
          return RecentlyEligibleTable
        case 'upcoming-renewals':
          return UpcomingRenewalsTable
        default:
          return null
      }
    }
  }
}
</script>
