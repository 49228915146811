<template>
  <BaseTable :rows="rows" :submitable="true" :accumulator-index="7">
    <template slot="header">
      <template>
        <td>UB-04</td>
        <td>CoreCare Status</td>
        <td>Claim #</td>
        <td>Dates of Service</td>
        <td class="align-left">Resident</td>
        <td class="align-left">Facility Name</td>
        <td>Claim Status</td>
        <td>Amount</td>
      </template>
    </template>
    <template slot="row" slot-scope="{ row }">
      <td v-for="(cell, j) in row.data" :key="j">
        <button
          v-if="cell.type === 'view-edit'"
          class="table-button table-button--hover-primary"
          @click="$eventHub.$emit('toggle-claim-panel')"
        >
          View/Edit
        </button>
        <div v-else-if="cell.type === 'errors'" class="relative-position">
          <button
            class="table-error-button"
            @click="toggleTooltip(`errors-tooltip-${row.id}`)"
          >
            {{ cell.value }} Errors
          </button>

          <ErrorsTooltip
            :ref="`errors-tooltip-${row.id}`"
            :variant="cell.variant"
          />
        </div>

        <div
          v-else-if="cell.type === 'status-review'"
          class="relative-position"
        >
          <button
            class="table-button table-button--hover-primary"
            @click="toggleTooltip(`core-care-status-tooltip-${row.id}`)"
          >
            Needs review
          </button>

          <CoreCareStatusTooltip
            :ref="`core-care-status-tooltip-${row.id}`"
            :variant="cell.variant"
          />
        </div>

        <div v-else-if="cell.type === 'claim-no'">
          <ClaimTooltip
            :ref="`claim-tooltip-${row.id}`"
            :label="cell.value"
            :variant="row.id"
          />
        </div>

        <span v-else-if="cell.type === 'resident'">
          <ResidentTooltip
            :ref="`resident-tooltip-${row.id}`"
            :label="cell.value"
          />
        </span>
        <span v-else-if="cell.type === 'facility'">
          <FacilityTooltip
            :ref="`resident-tooltip-${row.id}`"
            :label="cell.value"
          />
        </span>
        <span v-else>
          {{ cell }}
        </span>
      </td>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable'
import ErrorsTooltip from '@/components/tooltips/ErrorsTooltip'
import CoreCareStatusTooltip from '@/components/tooltips/CoreCareStatusTooltip'
import ClaimTooltip from '@/components/tooltips/ClaimTooltip'
import ResidentTooltip from '@/components/tooltips/ResidentTooltip'
import FacilityTooltip from '@/components/tooltips/FacilityTooltip'
import claimIdVariator from '@/variators/claimIdVariator'
import dollarVariantVariator from '@/variators/dollarValueVariator'

const rowTemplate = (id, errorsVariant, statusReviewVariant, fields) => ({
  id,
  data: [
    {
      type: !errorsVariant ? 'view-edit' : 'errors',
      value: (() => {
        switch (errorsVariant) {
          case '1a':
          case '1b':
            return 1
          case '2':
            return 2
          default:
            return 2
        }
      })(),
      variant: errorsVariant
    },
    {
      type: 'status-review',
      variant: !statusReviewVariant ? 'blank' : statusReviewVariant
    },
    {
      value: claimIdVariator(),
      type: 'claim-no'
    },
    ...fields,
    dollarVariantVariator(772, 2790)
  ]
})

export default {
  components: {
    BaseTable,
    ErrorsTooltip,
    CoreCareStatusTooltip,
    ClaimTooltip,
    ResidentTooltip,
    FacilityTooltip
  },
  methods: {
    toggleTooltip(ref) {
      this.$refs[ref][0].toggle()
    }
  },
  data() {
    return {
      rows: [
        rowTemplate(1, 'florina', 'florina', [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Abeyta, Florina',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(2, null, 'b', [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Grissom, Douglas',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(3, null, 'c', [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Glee, Marissa',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(4, '1a', 'd', [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Griffin, Louis',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(5, null, 'e', [
          '01/04/2020 - 01/13/2020',
          {
            value: 'McAlpha, John',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(6, '1b', null, [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Merryweather, Lisa',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(7, null, null, [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Norman, Paul',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(8, null, null, [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Pyke, Jerome',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(9, null, null, [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Queen, Albert',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ]),
        rowTemplate(10, null, null, [
          '01/04/2020 - 01/13/2020',
          {
            value: 'Zuvia, Mustafa',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          'Held'
        ])
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  td {
    text-align: center;
  }

  td.align-left {
    padding-left: 12px !important;
    text-align: left;
  }
}
</style>
