<template>
  <BaseTable
    :rows="rows"
    :submitable="true"
    submit-button-text="Submit Reconsideration"
    :accumulator-index="7"
  >
    <template slot="header">
      <template>
        <td>UB-04</td>
        <td>Date Submitted</td>
        <td>Date Rejected/Denied</td>
        <td>Claim #</td>
        <td class="align-left">Resident</td>
        <td class="align-left">Facility Name</td>
        <td>Payor Remit</td>
        <td>Amount</td>
      </template>
    </template>
    <template slot="row" slot-scope="{ row }">
      <template>
        <td v-for="(cell, j) in row.data" :key="j">
          <button
            v-if="cell.dynamic && cell.type === 'view-edit'"
            class="table-button table-button--hover-primary"
            @click="$eventHub.$emit('toggle-claim-panel')"
          >
            View/Edit
          </button>
          <button
            v-else-if="cell.dynamic && cell.type === 'view-with-icon'"
            class="table-button"
          >
            <img class="view-icon" src="@/assets/images/view-icon.svg" />
            <span>View</span>
          </button>
          <span v-else-if="cell.type === 'claim-no'">
            <ClaimTooltip
              :ref="`claim-tooltip-${row.id}`"
              :label="cell.value"
              :variant="row.id"
            />
          </span>
          <span v-else-if="cell.type === 'resident'">
            <ResidentTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </span>
          <span v-else-if="cell.type === 'facility'">
            <FacilityTooltip
              :ref="`resident-tooltip-${row.id}`"
              :label="cell.value"
            />
          </span>
          <span v-else>
            {{ cell }}
          </span>
        </td>
      </template>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable'
import ClaimTooltip from '@/components/tooltips/ClaimTooltip'
import ResidentTooltip from '@/components/tooltips/ResidentTooltip'
import FacilityTooltip from '@/components/tooltips/FacilityTooltip'
import claimIdVariator from '@/variators/claimIdVariator'
import dollarVariantVariator from '@/variators/dollarValueVariator'

const rowTemplate = (id, fields) => ({
  id: id,
  data: [
    {
      dynamic: true,
      type: 'view-edit'
    },
    ...fields,
    dollarVariantVariator(772, 2790)
  ]
})

export default {
  components: {
    BaseTable,
    ClaimTooltip,
    ResidentTooltip,
    FacilityTooltip
  },
  data() {
    return {
      rows: [
        rowTemplate(1, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Gwin, Anita',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(2, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Gunkel, Michael',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(3, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Grissom, Douglas',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(4, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Glee, Marissa',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(5, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Griffin, Louis',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(6, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'McAlpha, John',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(7, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Merryweather, Lisa',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(8, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Norman, Paul',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(9, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Pyke, Jerome',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ]),
        rowTemplate(10, [
          '01/04/2020',
          '01/13/2020',
          {
            value: claimIdVariator(),
            type: 'claim-no'
          },
          {
            value: 'Queen, Albert',
            type: 'resident'
          },
          {
            value: 'Mountain View Health & Rehabilitation',
            type: 'facility'
          },
          {
            dynamic: true,
            type: 'view-with-icon'
          }
        ])
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.view-icon {
  height: 15px;
  margin-right: 7px;
  width: 12.27px;
}

table {
  td {
    text-align: center;
  }

  td.align-left {
    padding-left: 12px !important;
    text-align: left;
  }
}
</style>
