<template>
  <div :class="['claim-panel', { 'claim-panel--visible': isVisible }]">
    <div class="claim-panel__header">
      <div>
        Claim # 12345678

        <button class="claim-panel__header__button">Save</button>
        <button class="claim-panel__header__button">Submit</button>
      </div>

      <div class="claim-panel__header__options">
        <img src="@/assets/images/notes.svg" />
        <img src="@/assets/images/bookmark.svg" />
        <img src="@/assets/images/printer.svg" />
        <img src="@/assets/images/kabob.svg" />
        <div class="claim-panel__header__close-button">
          <img
            @click="isVisible = false"
            src="@/assets/images/close-drawer.svg"
          />
        </div>
      </div>
    </div>

    <img src="@/assets/images/claim.jpg" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.claim-panel {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: #1d1c1d;
  font-size: 16px;
  letter-spacing: 0.218182px;
  line-height: 24px;
  overflow: hidden;
  position: absolute;
  right: -1000px;
  top: 49px;
  transition: right 0.3s linear;
  width: 1000px;
  z-index: 5;

  &--visible {
    right: 0;
  }

  &__header {
    align-items: center;
    border-bottom: 1px solid #b7bac2;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 12px 20px;

    &__button {
      background: #622aeb;
      border: none;
      border-radius: 4px;
      box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.102956);
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 28px;
      line-height: 16px;
      margin-left: 11px;
      width: 84px;

      &:first-of-type {
        margin-left: 28px;
      }
    }

    &__options {
      align-items: center;
      display: flex;
      gap: 28px;
    }

    &__close-button {
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 36px;
      justify-content: center;
      width: 36px;

      &:hover {
        background: #eef0f3;
      }
    }
  }
}
</style>
