<template>
  <table class="table">
    <thead class="table__header">
      <tr>
        <th colspan="4" class="table__header__th">
          Levels of Service
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <tr>
        <th class="table__body__th">#</th>
        <th class="table__body__th">Eff. date</th>
        <th class="table__body__th">End date</th>
        <th class="table__body__th">Level</th>
      </tr>
      <tr
        v-for="(item, i) in data"
        :key="i"
        :class="{ 'table__body__tr--mismatch': item.isMismatch }"
      >
        <td
          class="table__body__td"
          :class="{ 'table__body__td--mismatch': item.isMismatch }"
        >
          {{ i + 1 }}
        </td>
        <td
          class="table__body__td"
          :class="{ 'table__body__td--mismatch': item.isMismatch }"
        >
          {{ item.effDate }}
        </td>
        <td
          class="table__body__td"
          :class="{ 'table__body__td--mismatch': item.isMismatch }"
        >
          {{ item.endDate }}
        </td>
        <td
          class="table__body__td"
          :class="{ 'table__body__td--mismatch': item.isMismatch }"
        >
          {{ item.level }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      instances: {
        default: {
          left: [
            {
              effDate: '05/01/2020',
              endDate: '07/15/2020',
              level: 'SE2'
            },
            {
              effDate: '07/16/2020',
              endDate: '02/13/2021',
              level: 'SE1'
            }
          ],
          right: [
            {
              effDate: '05/01/2020',
              endDate: '07/15/2020',
              level: 'SE2'
            },
            {
              effDate: '07/16/2020',
              endDate: '02/13/2021',
              level: 'SE1'
            }
          ]
        },
        florina: {
          left: [
            {
              effDate: '02/01/2020',
              endDate: '03/03/2020',
              level: 'RAC'
            },
            {
              effDate: '03/04/2020',
              endDate: '05/31/2020',
              level: 'RAC'
            },
            {
              effDate: '06/01/2020',
              endDate: '08/26/2020',
              level: 'RAC'
            },
            {
              effDate: '08/27/2020',
              endDate: '10/18/2020',
              level: 'RAC'
            },
            {
              isMismatch: true,
              effDate: '10/19/2020',
              endDate: '02/18/2021',
              level: 'RAD'
            }
          ],
          right: [
            {
              effDate: '02/01/2020',
              endDate: '03/03/2020',
              level: 'RAC'
            },
            {
              effDate: '03/04/2020',
              endDate: '05/31/2020',
              level: 'RAC'
            },
            {
              effDate: '06/01/2020',
              endDate: '08/26/2020',
              level: 'RAC'
            },
            {
              effDate: '08/27/2020',
              endDate: '10/18/2020',
              level: 'RAC'
            },
            {
              isMismatch: true,
              effDate: '',
              endDate: '',
              level: ''
            }
          ]
        }
      }
    }
  },
  computed: {
    data() {
      return this.instances[this.$store.state.typeOfSideBySide][this.type]
    }
  }
}
</script>
