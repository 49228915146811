import Vue from 'vue'
import VueRouter from 'vue-router'
import ManagerDashboard from '../views/ManagerDashboard.vue'
import ExecutiveDashboard from '../views/ExecutiveDashboard.vue'
import EndUserDashboard from '../views/EndUserDashboard.vue'
import BenefitsEligibilityWorkview from '@/views/BenefitsEligibilityWorkview'
import ArAgingWorkview from '@/views/ArAgingWorkview'
import ResidentsWorkview from '@/views/ResidentsWorkview'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/manager'
  },
  {
    path: '/manager',
    component: ManagerDashboard,
    name: 'Manager Dashboard'
  },
  {
    path: '/executive',
    component: ExecutiveDashboard,
    name: 'Executive Dashboard'
  },
  {
    path: '/end-user',
    component: EndUserDashboard,
    name: 'End User Dashboard'
  },
  {
    path: '/end-user/benefits-eligibility',
    component: BenefitsEligibilityWorkview,
    name: 'Benefits and Eligibility Workview'
  },
  {
    path: '/end-user/ar-aging',
    component: ArAgingWorkview,
    name: 'AR Aging Workview'
  },
  {
    path: '/end-user/residents',
    component: ResidentsWorkview,
    name: 'Residents Workview'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
