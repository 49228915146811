<template>
  <div class="row">
    <div class="row__column row__column--1">
      <CensusWidget class="mt mb" />
      <DailySalesOutstandingWidget class="mb" />
      <ClaimsWidget class="mb" />
      <ManagerArAgingWidget class="stretch-widget" />
    </div>

    <div class="row__column row__column--2">
      <ManagerDashboardSummary class="mb--lg stretch-widget" />
      <TotalEmployeeUsage />
      <TeamMetricsWidget class="team-metrics-widget" />
    </div>

    <div class="row__column row__column--3">
      <MyTasksWidget class="mt" />
      <RecentActivityWidget class="mt stretch-widget" />
    </div>
  </div>
</template>

<script>
import CensusWidget from '@/components/widgets/CensusWidget'
import ManagerDashboardSummary from '@/components/widgets/ManagerDashboardSummary'
import DailySalesOutstandingWidget from '@/components/widgets/DailySalesOutstandingWidget'
import ManagerArAgingWidget from '@/components/widgets/ManagerArAgingWidget'
import ClaimsWidget from '@/components/widgets/ClaimsWidget'
import TotalEmployeeUsage from '@/components/widgets/ManagerTotalEmployeeUsage'
import TeamMetricsWidget from '@/components/widgets/TeamMetricsWidget'
import RecentActivityWidget from '@/components/widgets/RecentActivityWidget'
import MyTasksWidget from '@/components/widgets/MyTasksWidget'

export default {
  components: {
    CensusWidget,
    ManagerDashboardSummary,
    DailySalesOutstandingWidget,
    ManagerArAgingWidget,
    ClaimsWidget,
    TotalEmployeeUsage,
    TeamMetricsWidget,
    RecentActivityWidget,
    MyTasksWidget
  }
}
</script>

<style lang="scss" scoped>
.mt {
  margin-top: 28px;
}

.mb {
  margin-bottom: 24px;

  &--lg {
    margin-bottom: 44px;
  }
}

.stretch-widget {
  flex: 1;
}

.team-metrics-widget {
  margin-top: -24px;
}

.row {
  display: flex;
  flex-direction: row;

  &__column {
    display: flex;
    flex-direction: column;

    &--1 {
      max-width: 290px;
    }

    &--2 {
      background-image: url('../assets/images/employee-usage-bg.svg');
      background-position: center -20px;
      background-repeat: no-repeat;
      background-size: 799px 799px;
      flex-grow: 1;
      min-width: 200px;
      padding: 28px 49px 0 49px;
    }

    &--3 {
      max-width: 594px;

      @media only screen and (max-width: 1700px) {
        max-width: 460px;
      }
    }
  }
}
</style>
