<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="half-progress-circle"
    height="167"
    width="224"
  >
    <path
      d="M18.473 159.575c-7.128-14.213-11.142-30.274-11.142-47.279C7.33 54.231 54.14 7.16 111.887 7.16c57.744 0 104.555 47.07 104.555 105.136 0 17.005-4.014 33.066-11.142 47.279"
      stroke="#dbdff1"
      stroke-linecap="round"
      stroke-width="14px"
    />
    <path
      d="M18.473 159.575c-7.128-14.213-11.142-30.274-11.142-47.279C7.33 54.231 54.14 7.16 111.887 7.16c57.744 0 104.555 47.07 104.555 105.136 0 17.005-4.014 33.066-11.142 47.279"
      stroke="url(#gradient)"
      pathLength="150"
      stroke-dasharray="150"
      stroke-linecap="round"
      stroke-width="14px"
      :stroke-dashoffset="strokeDashoffset"
    />
    <defs>
      <linearGradient
        id="gradient"
        x1="195"
        y1="63"
        x2="105.539"
        y2="159.575"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0E3457" />
        <stop offset="1" stop-color="#501B7B" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    strokeDashoffset: {
      required: true,
      type: Number
    }
  }
}
</script>
