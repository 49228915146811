<template>
  <BaseWidget :lg="true">
    <p class="widget__title">Recent Activity</p>
    <div class="activities">
      <div class="activity">
        <div class="activity__circle">
          <img
            class="activity__circle__item"
            src="@/assets/images/activity-circle.svg"
            alt="Activity Point"
          />
          <div class="activity__circle__line" />
        </div>
        <div class="activity__box">
          <div class="activity__box__title-bar">
            <img
              class="activity__box__avatar"
              src="@/assets/images/activity-avatar-1.png"
            />
            <div class="activity__box__name">Lindsay B.</div>
            <div class="activity__box__time">5 mins ago</div>
            <div class="activity__box__comment-box">
              <img
                class="activity__box__comment-box__icon"
                src="@/assets/images/comment-icon.svg"
              />
              <span class="activity__box__comment-box__label">
                Comment
              </span>
            </div>
          </div>
          <div class="activity__box__content">
            Submitted 556 Managed Medicaid claims
          </div>
        </div>
      </div>
      <div class="activity">
        <div class="activity__circle">
          <img
            class="activity__circle__item"
            src="@/assets/images/activity-circle.svg"
            alt="Activity Point"
          />
          <div
            class="activity__circle__line activity__circle__line--from-top activity__circle__line--full-height"
          />
        </div>
        <div class="activity__box">
          <div class="activity__box__title-bar">
            <img
              class="activity__box__avatar"
              src="@/assets/images/activity-avatar-2.png"
            />
            <div class="activity__box__name">Jennifer A.</div>
            <div class="activity__box__time">2 hours ago</div>
            <div class="activity__box__comment-box">
              <img
                class="activity__box__comment-box__icon"
                src="@/assets/images/comment-icon.svg"
              />
              <span class="activity__box__comment-box__label">
                Comment
              </span>
            </div>
          </div>
          <div class="activity__box__content">
            Submitted 105 HMO claims
          </div>
        </div>
      </div>
      <div class="activity">
        <div class="activity__circle">
          <img
            class="activity__circle__item"
            src="@/assets/images/activity-circle.svg"
            alt="Activity Point"
          />
          <div
            class="activity__circle__line activity__circle__line--from-top activity__circle__line--full-height"
          />
        </div>
        <div class="activity__box">
          <div class="activity__box__title-bar">
            <img
              class="activity__box__avatar"
              src="@/assets/images/activity-avatar-3.jpg"
            />
            <div class="activity__box__name">Mulah K.</div>
            <div class="activity__box__time">3 hours ago</div>
            <div class="activity__box__comment-box">
              <img
                class="activity__box__comment-box__icon"
                src="@/assets/images/comment-icon.svg"
              />
              <span class="activity__box__comment-box__label">
                Comment
              </span>
            </div>
          </div>
          <div class="activity__box__content">
            Submitted 45 Wheelchair claims to TMHP
          </div>
        </div>
      </div>
      <div class="activity">
        <div class="activity__circle">
          <img
            class="activity__circle__item"
            src="@/assets/images/activity-circle.svg"
            alt="Activity Point"
          />
          <div
            class="activity__circle__line activity__circle__line--from-top activity__circle__line--full-height"
          />
        </div>
        <div class="activity__box">
          <div class="activity__box__title-bar">
            <img
              class="activity__box__avatar"
              src="@/assets/images/activity-avatar-4.jpg"
            />
            <div class="activity__box__name">John D.</div>
            <div class="activity__box__time">5 mins ago</div>
            <div class="activity__box__comment-box">
              <img
                class="activity__box__comment-box__icon"
                src="@/assets/images/comment-icon.svg"
              />
              <span class="activity__box__comment-box__label">
                Comment
              </span>
            </div>
          </div>
          <div class="activity__box__content">
            Submitted 20 Managed Medicaid claims for reconsideration
          </div>
        </div>
      </div>
      <div class="activity">
        <div class="activity__circle">
          <img
            class="activity__circle__item"
            src="@/assets/images/activity-circle.svg"
            alt="Activity Point"
          />
          <div
            class="activity__circle__line activity__circle__line--from-top activity__circle__line--full-height"
          />
        </div>
        <div class="activity__box">
          <div class="activity__box__title-bar">
            <img
              class="activity__box__avatar"
              src="@/assets/images/activity-avatar-5.jpg"
            />
            <div class="activity__box__name">Delaney B.</div>
            <div class="activity__box__time">2 hours ago</div>
            <div class="activity__box__comment-box">
              <img
                class="activity__box__comment-box__icon"
                src="@/assets/images/comment-icon.svg"
              />
              <span class="activity__box__comment-box__label">
                Comment
              </span>
            </div>
          </div>
          <div class="activity__box__content">
            Submitted 35 Vent claims to TMHP
          </div>
        </div>
      </div>
      <div class="activity">
        <div class="activity__circle">
          <img
            class="activity__circle__item"
            src="@/assets/images/activity-circle.svg"
            alt="Activity Point"
          />
          <div
            class="activity__circle__line activity__circle__line--from-top"
          />
        </div>
        <div class="activity__box">
          <div class="activity__box__title-bar">
            <img
              class="activity__box__avatar"
              src="@/assets/images/activity-avatar-6.jpg"
            />
            <div class="activity__box__name">Griffin A.</div>
            <div class="activity__box__time">3 hours ago</div>
            <div class="activity__box__comment-box">
              <img
                class="activity__box__comment-box__icon"
                src="@/assets/images/comment-icon.svg"
              />
              <span class="activity__box__comment-box__label">
                Comment
              </span>
            </div>
          </div>
          <div class="activity__box__content">
            Submitted 324 Managed Medicaid claims
          </div>
        </div>
      </div>
    </div>
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'

export default {
  components: { BaseWidget }
}
</script>

<style lang="scss" scoped>
.widget {
  height: 326px;
  overflow: hidden;
  padding: 20px 30px 0 30px;
  // width: 594px;
}

.activities {
  margin-top: 15px;
  max-height: 269px;
  overflow-y: scroll;
}

.activity {
  $space-between-activities: 14px;

  display: flex;
  flex-direction: row;
  margin-bottom: $space-between-activities;

  &__circle {
    position: relative;
    width: 51px;

    &__item {
      height: 11px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 11px;
      z-index: 2;
    }

    &__line {
      background-color: #bfc5ca;
      height: calc(50% + #{$space-between-activities});
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%);
      width: 1px;
      z-index: 1;

      &--from-top {
        height: 50%;
        top: 0;
      }

      &--full-height {
        height: calc(100% + #{$space-between-activities});
      }
    }
  }

  &__box {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    margin-left: auto;
    margin-right: 10px;
    padding: 10px 8px 24px 17.59px;
    width: 483px;

    &__title-bar {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    &__avatar {
      border-radius: 50%;
      height: 24px;
      width: 24px;
    }

    &__name {
      color: #323c47;
      font-size: 14px;
      letter-spacing: 0.01em;
      line-height: 17px;
      margin-left: 15px;
    }

    &__time {
      color: #a3a3a3;
      font-size: 11px;
      font-weight: 300;
      line-height: 20px;
      margin-left: 6px;
    }

    &__comment-box {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-left: auto;

      &__icon {
        height: auto;
        width: 23px;
      }

      &__label {
        color: #a3a3a3;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        margin-left: 6px;
      }
    }

    &__content {
      color: #383d4b;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      margin-top: 13px;
    }
  }
}
</style>
