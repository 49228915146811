<template>
  <div class="checkbox__wrapper">
    <div class="checkbox" @click="toggle">
      <img
        v-if="checked"
        class="checkbox__image"
        src="@/assets/images/checkbox-checked.svg"
      />
      <img
        v-else
        class="checkbox__image"
        src="@/assets/images/checkbox-unchecked.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  justify-content: center;
  user-select: none;

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__image {
    height: 20px;
    width: 20px;
  }
}
</style>
