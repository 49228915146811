<template>
  <BaseWidget>
    <p class="widget__title">Census</p>
    <p class="widget__value">
      3,542 beds
      <span class="widget__percentage--up">
        <img src="@/assets/images/caret-up.svg" />
        10%
      </span>
    </p>
    <span class="widget__subtext">
      Compared to 3,220 last month
    </span>
    <img src="@/assets/images/graph-census.svg" />
  </BaseWidget>
</template>

<script>
import BaseWidget from '@/components/widgets/BaseWidget'

export default {
  components: { BaseWidget }
}
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px 30px;

  &__value {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
  }
}
</style>
