<template>
  <BaseTable
    :rows="currentRows"
    :selectable="false"
    :grid="true"
    :rich-hover="false"
  >
    <template slot="header">
      <td>
        <div class="breadcrumbs">
          <div class="breadcrumbs__item" @click="setDeepRows(rows, -1)">
            Facilities
          </div>
          <div class="breadcrumbs__arrow">
            <img src="@/assets/images/deep-arrow.svg" />
          </div>
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <div
              class="breadcrumbs__item"
              :key="i"
              @click="setDeepRows(breadcrumb, i)"
            >
              {{ breadcrumbLabel(breadcrumb.label || breadcrumb.value) }}
            </div>
            <div :key="`${i}-arrow`" class="breadcrumbs__arrow">
              <img src="@/assets/images/deep-arrow.svg" />
            </div>
          </template>
        </div>
      </td>
      <td v-if="breadcrumbs.length === 2">Claims Status</td>
      <td>Total AR</td>
      <td>0-30 days</td>
      <td>31-60 days</td>
      <td>61-90 days</td>
      <td>91- 120 days</td>
      <td>121+ days</td>
    </template>
    <template slot="row" slot-scope="{ row }">
      <td v-for="(cell, j) in row.data" :key="j">
        <div
          v-if="cell.dynamic && cell.type === 'deep-rows'"
          class="deep-selector"
          @click="setDeepRows(cell)"
        >
          <span>{{ cell.value }}</span>
          <img
            class="deep-selector__arrow"
            src="@/assets/images/deep-arrow.svg"
          />
        </div>
        <div v-else-if="cell.dynamic && cell.type === 'view-claim'">
          <span class="view-claim-value">{{ cell.value }}</span>
          <button class="table-button table-button--hover-primary">
            View/Edit
          </button>
        </div>
        <div v-else-if="cell.dynamic && cell.type === 'deep-rows-tooltip'">
          <PriceTooltip :cell="cell" @show-claims="setDeepRows(cell)" />
        </div>
        <span v-else>
          {{ cell }}
        </span>
      </td>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '@/components/tables/BaseTable'
import PriceTooltip from '@/components/tooltips/PriceTooltip'

const days6190RowsTemplate = id => {
  const row = (idDeep, name, fields) => ({
    id: `${id}-${idDeep}`,
    data: [
      {
        dynamic: true,
        type: 'view-claim',
        value: name
      },
      ...fields
    ]
  })

  return [
    row(1, 'Claim 50603855', ['Denied', '$3,201', '', '', '$3,201', '', '']),
    row(2, 'Claim 20035927', ['Ready', '$3,468', '', '', '$3,468', '', '']),
    row(3, 'Claim 25200867', ['Rejected', '$1,067', '', '', '$1,067', '', '']),
    row(4, 'Claim 72581076', ['Pending', '$1,867', '', '', '$1,867', '', '']),
    row(5, 'Claim 4893439', ['Pending', '$5,601', '', '', '$5,601', '', '']),
    row(6, 'Claim 80006133', ['Pending', '$2,401', '', '', '$2,401', '', '']),
    row(7, 'Claim 95855746', ['Rejected', '$2,934', '', '', '$2,934', '', '']),
    row(8, 'Claim 26238420', ['Ready', '$4,001', '', '', '$4,001', '', '']),
    row(9, 'Claim 93617619', ['Ready', '$25.37', '', '', '$25.37', '', '']),
    row(10, 'Claim 97007532', [
      'Rejected',
      '$302.50',
      '',
      '',
      '$302.50',
      '',
      ''
    ])
  ]
}

const thirdLevelRowTemplate = (id, idDeep, name, fields) => ({
  id: `${id}-${idDeep}`,
  data: [
    {
      dynamic: true,
      type: 'view-claim',
      value: name
    },
    ...fields
  ]
})

const secondLevelRowTemplate = (id, idDeep, name, fields) => ({
  id: `${id}-${idDeep}`,
  data: [
    {
      dynamic: true,
      type: 'deep-rows',
      value: name,
      rows: [
        thirdLevelRowTemplate(idDeep, 1, 'Claim 50603855', [
          'Denied',
          '$2,738',
          '$2,738',
          '',
          '',
          '',
          ''
        ]),
        thirdLevelRowTemplate(idDeep, 2, 'Claim 20035927', [
          'Ready',
          '$4,100',
          '',
          '$4,100',
          '',
          '',
          ''
        ]),
        thirdLevelRowTemplate(idDeep, 3, 'Claim 25200867', [
          'Rejected',
          '$2,900',
          '',
          '',
          '$2,900',
          '',
          ''
        ]),
        thirdLevelRowTemplate(idDeep, 4, 'Claim 72581076', [
          'Pending',
          '$5,168',
          '$5,168',
          '',
          '',
          '',
          ''
        ])
      ]
    },
    ...fields
  ]
})

const rowTemplate = (id, name, fields) => {
  const row = {
    id,
    data: [
      {
        dynamic: true,
        type: 'deep-rows',
        value: name,
        rows: [
          secondLevelRowTemplate(id, 1, 'Vitela, Francisco', [
            '$15,050',
            '$7,950',
            '$4,100',
            '$2,900',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 2, 'Abeyta, Florina', [
            '$6,042',
            '$6,042',
            '',
            '',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 3, 'Acosta, Porfirio', [
            '$7,043',
            '$7,043',
            '',
            '',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 4, 'Blanton, James', [
            '$24,482',
            '$18,834',
            '$3,215',
            '$655',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 5, 'Black, Harry', [
            '$30,125',
            '$6,042',
            '',
            '$24,083',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 6, 'Cannada, Dorothy', [
            '$3,256',
            '',
            '$3,256',
            '',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 7, 'Cave, Randy', [
            '$2,011',
            '$2,011',
            '',
            '',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 8, 'Ferrell, Jerry', [
            '$25',
            '',
            '',
            '',
            '$25',
            ''
          ]),
          secondLevelRowTemplate(id, 9, 'Green, Hattie', [
            '-$36',
            '',
            '',
            '-$36',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 10, 'Gwin, Anita', [
            '$360',
            '',
            '$360',
            '',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 11, 'Gunkel, Michael', [
            '$2,011',
            '',
            '',
            '$2,011',
            '',
            ''
          ]),
          secondLevelRowTemplate(id, 12, 'Grissom, Douglas', [
            '$3,256',
            '$814',
            '$2,442',
            '',
            '',
            ''
          ])
        ]
      },
      ...fields
    ]
  }

  // TODO: Make it more generic for multiple injections, this implementation does only one injection.
  const injectParentIndex = row.data.findIndex(
    item => item.parent && item.parent.startsWith('this')
  )

  if (injectParentIndex > -1) {
    const parentIndex = row.data[injectParentIndex].parent.split('.')[1]
    row.data[injectParentIndex].parent = row.data[parentIndex]
  }

  return row
}

export default {
  components: {
    BaseTable,
    PriceTooltip
  },
  data() {
    return {
      deepRows: null,
      breadcrumbs: [],
      rows: [
        rowTemplate(1, 'Lakeside Manor', [
          '$421,115',
          '$323,965',
          '$55,302',
          {
            dynamic: true,
            type: 'deep-rows-tooltip',
            value: '$26,986',
            label: '61-90 days',
            rows: [...days6190RowsTemplate(1)],
            parent: 'this.0'
          },
          '$11,269',
          '$3,593'
        ]),
        rowTemplate(2, 'St. Barnabas Home', [
          '$359,788',
          '$276,78',
          '$47,248',
          '$23,052',
          '$9,628',
          '$3,070'
        ]),
        rowTemplate(3, 'Long Term Care Center of America', [
          '$359,788',
          '$276,786',
          '$47,248',
          '$23,056',
          '$9,628',
          '$3,070'
        ]),
        rowTemplate(4, 'Mountain Peace North', [
          '$355,699',
          '$273,641',
          '$46,711',
          '$22,794',
          '$9,518',
          '$3,035'
        ]),
        rowTemplate(5, 'Prairie Springs', [
          '$306,637',
          '$235,897',
          '$40,268',
          '$19,650',
          '$8,205',
          '$2,616'
        ]),
        rowTemplate(6, 'St. Thomas Home', [
          '$367,965',
          '$283,077',
          '$48,322',
          '$23,580',
          '$9,846',
          '$3,139'
        ]),
        rowTemplate(7, 'Green Mountain Springs', [
          '$363,876',
          '$279,931',
          '$47,785',
          '$23,318',
          '$9,737',
          '$3,104'
        ])
      ]
    }
  },
  computed: {
    currentRows() {
      return this.deepRows || this.rows
    }
  },
  methods: {
    breadcrumbLabel(label) {
      if (label.length > 16) {
        return label.slice(0, 13) + '...'
      }

      return label
    },
    setDeepRows(cell, reverseIndex) {
      this.deepRows = cell.rows

      if (reverseIndex >= 0) {
        this.breadcrumbs = this.breadcrumbs.slice(0, reverseIndex + 1)
      } else if (reverseIndex === -1) {
        this.breadcrumbs = []
      } else {
        if (cell.parent) {
          this.breadcrumbs.push(cell.parent)
        }

        this.breadcrumbs.push(cell)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  // Center the heading.
  thead td {
    text-align: center;

    &:first-of-type {
      text-align: left;
    }
  }

  .breadcrumbs {
    align-items: center;
    display: flex;
    flex-direction: row;

    &__item {
      cursor: pointer;
      display: inline-block;
      padding: 6px 11px;

      &:hover {
        background: #eef0f3;
        border-radius: 4px;
      }
    }

    &__arrow {
      justify-content: center;
      margin: 5px 12px 0 12px;

      img {
        height: 20px;
        width: 20px;
      }

      &:last-of-type {
        display: none;
      }
    }
  }

  .deep-selector {
    align-items: center;
    border-radius: 4px;
    color: #1d1c1d;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 14px;
    letter-spacing: 0.228571px;
    line-height: 20px;
    padding: 8px 8px 8px 14px;
    transition: 0.3s ease;

    &__arrow {
      display: block;
      height: 20px;
      margin-left: 9px;
      opacity: 0;
      transition: 0.3s ease;
      width: 20px;
    }

    &:hover {
      background: #fff;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    }

    &:hover .deep-selector__arrow {
      opacity: 1;
    }
  }

  .view-claim-value {
    margin-right: 62px;
  }
}
</style>
